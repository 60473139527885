import React, { useState, useEffect } from 'react'
import { Modal, Tabs, Tab, Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Oval from 'react-loading-icons/dist/esm/components/oval'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getCampaigns } from '../../features/CampaignsSlice'
import DeleteModal from './DeleteModal'
import Helpers from '../../assets/js/Helpers'

export default function EditCampaignModal(props) {
  const campaigns = useSelector((state) => state.campaigns.campaigns)
  const { user, stations } = useSelector((state) => state)
  const media = useSelector((state) => state.media)
  const dispatch = useDispatch()
  const [campaignFound, setCampaignFound] = useState(false)
  const [name, setName] = useState('')
  const [campaignType, setCampaignType] = useState('')
  const [stationId, setStationId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState()
  const [endTime, setEndTime] = useState('')
  const [timezone, setTimezone] = useState('')
  const [allDayChecked, setAllDayChecked] = useState(false)
  const [sundayChecked, setSundayChecked] = useState(false)
  const [mondayChecked, setMondayChecked] = useState(false)
  const [tuesdayChecked, setTuesdayChecked] = useState(false)
  const [wednesdayChecked, setWednesdayChecked] = useState(false)
  const [thursdayChecked, setThursdayChecked] = useState(false)
  const [fridayChecked, setFridayChecked] = useState(false)
  const [saturdayChecked, setSaturdayChecked] = useState(false)
  const [recurring, setRecurring] = useState('')
  const [daySelect, setDaySelect] = useState('')
  const [dayDescription, setDayDescription] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const [checkIds, setCheckIds] = useState([])
  const [chargeScreenText1, setChargeScreenText1] = useState('')
  const [chargeScreenText2, setChargeScreenText2] = useState('')
  const [chargeScreenText3, setChargeScreenText3] = useState('')
  const [chargeScreenText4, setChargeScreenText4] = useState('')
  const [chargeScreenText5, setChargeScreenText5] = useState('')
  const [chargeScreenText6, setChargeScreenText6] = useState('')
  const [chargeScreenText7, setChargeScreenText7] = useState('')
  const [chargeScreenText8, setChargeScreenText8] = useState('')
  const [chargeScreenText9, setChargeScreenText9] = useState('')
  const [chargeScreenText10, setChargeScreenText10] = useState('')

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const updateCampaign = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    /* Get array of image id's for for campaign */
    var imageIds = []
    var checkboxes = document.querySelectorAll('input[class=image-select]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      imageIds.push(checkboxes[i].value)
    }

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('campaign_id', props.editId)
    formData.append('name', name)
    formData.append('type', campaignType)
    formData.append('station_id', stationId)
    formData.append('start_date', startDate)
    formData.append('start_time', startTime)
    formData.append('end_date', endDate)
    formData.append('end_time', endTime)
    formData.append('timezone', 'CST')
    formData.append('all_day', allDayChecked)
    formData.append('sunday', sundayChecked)
    formData.append('monday', mondayChecked)
    formData.append('tuesday', tuesdayChecked)
    formData.append('wednesday', wednesdayChecked)
    formData.append('thursday', thursdayChecked)
    formData.append('friday', fridayChecked)
    formData.append('saturday', saturdayChecked)
    formData.append('recurring', recurring)
    formData.append('monthly_period', daySelect)
    formData.append('image_ids', imageIds)
    formData.append('charge_screen_text_1', chargeScreenText1)
    formData.append('charge_screen_text_2', chargeScreenText2)
    formData.append('charge_screen_text_3', chargeScreenText3)
    formData.append('charge_screen_text_4', chargeScreenText4)
    formData.append('charge_screen_text_5', chargeScreenText5)
    formData.append('charge_screen_text_6', chargeScreenText6)
    formData.append('charge_screen_text_7', chargeScreenText7)
    formData.append('charge_screen_text_8', chargeScreenText8)
    formData.append('charge_screen_text_9', chargeScreenText9)
    formData.append('charge_screen_text_10', chargeScreenText10)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/campaign/update', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      props.toggleShowEditModal()
      dispatch(getCampaigns())

    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {

    /* Get device from state */
    let campaign = campaigns.find(obj => {
      return obj.id == props.editId
    })

    if (campaign !== undefined && campaign !== "undefined" && campaign !== null) {
      setCampaignFound(true)
      setName(campaign.name)
      setCampaignType(campaign.type)
      setStationId(campaign.campaign_stations)
      setStartDate(campaign.campaign_times[0].start_time.split(" ")[0])
      setStartTime(campaign.campaign_times[0].start_time.split(" ")[1])
      setEndDate(campaign.campaign_times[campaign.campaign_times.length - 1].end_time.split(" ")[0])
      setEndTime(campaign.campaign_times[campaign.campaign_times.length - 1].end_time.split(" ")[1])
      setTimezone("CST")
      setAllDayChecked(campaign.all_day === 1 ? true : false)
      setRecurring(campaign.recurring_period)
      setDaySelect(campaign.monthly_option)
      setChargeScreenText1(campaign.charge_screen_texts[0])
      setChargeScreenText2(campaign.charge_screen_texts[1])
      setChargeScreenText3(campaign.charge_screen_texts[2])
      setChargeScreenText4(campaign.charge_screen_texts[3])
      setChargeScreenText5(campaign.charge_screen_texts[4])
      setChargeScreenText6(campaign.charge_screen_texts[5])
      setChargeScreenText7(campaign.charge_screen_texts[6])
      setChargeScreenText8(campaign.charge_screen_texts[7])
      setChargeScreenText9(campaign.charge_screen_texts[8]) 
      setChargeScreenText10(campaign.charge_screen_texts[9])

      /* Set weekly checkboxes if recurring is weekly */
      if (campaign.recurring_period == "WEEKLY") {
        campaign.campaign_times.forEach((time) => {
          let campaign_time = new Date(time.start_time)

          if (campaign_time.getDay() == 0) {
            setSundayChecked(true)
          } else if (campaign_time.getDay() == 1) {
            setMondayChecked(true)
          } else if (campaign_time.getDay() == 2) {
            setTuesdayChecked(true)
          } else if (campaign_time.getDay() == 3) {
            setWednesdayChecked(true)
          } else if (campaign_time.getDay() == 4) {
            setThursdayChecked(true)
          } else if (campaign_time.getDay() == 5) {
            setFridayChecked(true)
          } else if (campaign_time.getDay() == 6) {
            setSaturdayChecked(true)
          }
        })
      }

      if (campaign.recurring_period == "MONTHLY" && campaign.monthly_option == "DATE") {
        let d = new Date(campaign.campaign_times[0].start_time.split(" ")[0])
        let dayOfMonth = d.getUTCDate()
        setDayDescription(Helpers.getOrdinal(dayOfMonth) + " of each month")
      }

      // Get image id's
      let ids = []
      if (campaign.campaign_media[0] !== null && campaign.campaign_media[0] !== undefined) {
        campaign.campaign_media.forEach((media) => {
          ids.push(media.id)
        })
      }

      setCheckIds(ids)

    } else {
      setCampaignFound(false)
    }
  }, [props.editId])

  const handleStationChange = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setStationId(value)
  }

  const handleMediaChange = (e) => {

    let targetValueInt = parseInt(e.target.value)

    if (checkIds.includes(targetValueInt)) {

      // Remove/uncheck it
      var array = [...checkIds]
      var index = array.indexOf(targetValueInt)

      if (index !== -1) {
        array.splice(index, 1);
        setCheckIds(array)
      }
    } else {
      // Add/check it
      setCheckIds([...checkIds, targetValueInt])
    }
  }

  return (
    <>
      {(() => {
        if (campaignFound !== undefined && campaignFound !== "undefined" && campaignFound !== null) {
          return (
            <Modal show={props.showEditModal} onHide={props.toggleShowEditModal} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Edit {name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form id="update_campaign_form" onSubmit={updateCampaign}>
                  <Tabs defaultActiveKey="details" className="mb-3">
                    <Tab eventKey="details" title="Details">
                      <div className="row">
                        <div className="col-12">
                          <div className="card m-b-30">
                            <div className="card-body">
                              <div className="form-group row">
                                <div className="col-sm-6">
                                  <label>Campaign Name</label>
                                  <input className="form-control" type="text" id="input-campaign-name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="col-sm-6">
                                  <label>Type</label>
                                  <select className="form-control" value={campaignType} onChange={(e) => setCampaignType(e.target.value)}>
                                    <option value="IDLE SCREEN">Idle Screen (Multimedia)</option>
                                    <option value="CHARGE SCREEN">Charging Screen (Text)</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group row m-t-30">
                                <div className="col-sm-6">
                                  <label>Station</label>
                                  <select className="form-control" multiple onChange={(e) => handleStationChange(e)}>
                                    {
                                      stations.stations.map((station, index) => (
                                        <option key={index} value={station.id} selected={stationId.includes(station.id)}>{station.name}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="dates" title="Dates">
                      <div className="row">
                        <div className="col-12">
                          <div className="card m-b-30">
                            <div className="card-body">
                              <div className="form-group row">
                                <div className="col-sm-6">
                                  <label>Start Date</label>
                                  <input className="form-control" type="date" value={startDate} id="input-start-date" onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div className="col-sm-6">
                                  <label>End Date</label>
                                  <input className="form-control" type="date" value={endDate} id="input-end-date" onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="run_time" title="Run time">
                      <div className="row">
                        <div className="col-12">
                          <div className="card m-b-30">
                            <div className="card-body">
                              <div>
                                <label style={{ fontSize: "13px" }}>All day</label>
                                <input style={{ verticalAlign: "middle", marginLeft: ".5rem" }} type="checkbox" checked={allDayChecked} onChange={() => setAllDayChecked(!allDayChecked)}></input>
                              </div>
                              <div className="form-group row m-t-30">
                                <div className="col-sm-6">
                                  <label>Start Time</label>
                                  <input className="form-control" type="time" disabled={allDayChecked} value={startTime} id="input-start-time" onChange={(e) => setStartTime(e.target.value + ":00")} />
                                </div>
                                <div className="col-sm-6">
                                  <label>End Time</label>
                                  <input className="form-control" type="time" disabled={allDayChecked} value={endTime} id="input-end-time" onChange={(e) => setEndTime(e.target.value + ":00")} />
                                </div>
                              </div>
                              <div className="form-group row m-t-30">
                                <div className="col-sm-6">
                                  <label>Recurring</label>
                                  <select className="form-control" id="recurring-select" value={recurring} onChange={(e) => setRecurring(e.target.value)}>
                                    <option value="EVERY_DAY">Every day</option>
                                    <option value="WEEKLY">Weekly</option>
                                    <option value="MONTHLY">Monthly</option>
                                  </select>
                                </div>
                                <div className="col-sm-6">

                                </div>
                              </div>
                              {(() => {
                                if (recurring == "WEEKLY") {
                                  return (
                                    <>
                                      <div style={{ marginTop: "2rem" }}>
                                        <label style={{ fontSize: "13px" }}>Days of the week</label>
                                      </div>
                                      <div style={{ marginTop: "1rem" }}>
                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={sundayChecked} onChange={() => { }} />
                                        <button className={"btn " + (sundayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setSundayChecked(!sundayChecked) }}>Sunday</button>

                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={mondayChecked} onChange={() => { }} />
                                        <button className={"btn " + (mondayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setMondayChecked(!mondayChecked) }}>Monday</button>

                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={tuesdayChecked} onChange={() => { }} />
                                        <button className={"btn " + (tuesdayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setTuesdayChecked(!tuesdayChecked) }}>Tuesday</button>

                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={wednesdayChecked} onChange={() => { }} />
                                        <button className={"btn " + (wednesdayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setWednesdayChecked(!wednesdayChecked) }}>Wednesday</button>

                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={thursdayChecked} onChange={() => { }} />
                                        <button className={"btn " + (thursdayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setThursdayChecked(!thursdayChecked) }}>Thursday</button>

                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={fridayChecked} onChange={() => { }} />
                                        <button className={"btn " + (fridayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setFridayChecked(!fridayChecked) }}>Friday</button>

                                        <input type="checkbox" className="btn-check" style={{ visibility: "hidden" }} id="btn-check-outlined" checked={saturdayChecked} onChange={() => { }} />
                                        <button className={"btn " + (saturdayChecked ? 'btn-primary' : 'btn-outline-primary')} onClick={(e) => { e.preventDefault(); setSaturdayChecked(!saturdayChecked) }}>Saturday</button>
                                      </div>
                                    </>
                                  )
                                } else if (recurring == "MONTHLY") {
                                  return (
                                    <div className="form-group row m-t-30">
                                      <div className="col-sm-6">
                                        <label>Select day</label>
                                        <select className="form-control" value={daySelect} onChange={(e) => setDaySelect(e.target.value)}>
                                          <option value="date">{dayDescription}</option>
                                        </select>
                                      </div>
                                      <div className="col-sm-6">
                                      </div>
                                    </div>
                                  )
                                }
                              })()}

                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    {campaignType === "IDLE SCREEN" ?
                      <Tab eventKey="media" title="Media">
                        <div style={{ textAlign: "center" }}>
                          {media.data.length > 0 && media.loading !== "pending" ? media.data.map((m) => {
                            return (
                              <Card style={{ width: "10rem", display: "inline-block", textAlign: "center" }}>
                                <Card.Img variant="top" style={{ maxWidth: "140px", padding: "7px" }} src={"data:image/png;base64," + m.base64} />
                                <Card.Body style={{ textAlign: "center" }}>
                                  <input style={{ marginTop: "15px" }} type="checkbox" className="image-select" value={m.id} checked={checkIds.includes(m.id)} onClick={(e) => handleMediaChange(e)} />
                                </Card.Body>
                              </Card>
                            )
                          }) : <div style={{ textAlign: "center", height: "25rem" }}><Oval stroke="#30419b" width="5rem" height="5rem" style={{ marginTop: "8rem" }} /></div>}
                        </div>
                      </Tab>
                      :
                      <></>
                    }

                    {campaignType === "CHARGE SCREEN" ?
                      <Tab eventKey="charge_screen_text" title="Charge Screen">
                        <span style={{ display: "block", marginTop: "2rem", fontSize: "14px" }}>Enter up to 10 texts to display on the charging screen</span>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText1} id="input-charge-screen-text" onChange={(e) => setChargeScreenText1(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText2} id="input-charge-screen-text" onChange={(e) => setChargeScreenText2(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText3} id="input-charge-screen-text" onChange={(e) => setChargeScreenText3(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText4} id="input-charge-screen-text" onChange={(e) => setChargeScreenText4(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText5} id="input-charge-screen-text" onChange={(e) => setChargeScreenText5(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText6} id="input-charge-screen-text" onChange={(e) => setChargeScreenText6(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText7} id="input-charge-screen-text" onChange={(e) => setChargeScreenText7(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText8} id="input-charge-screen-text" onChange={(e) => setChargeScreenText8(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText9} id="input-charge-screen-text" onChange={(e) => setChargeScreenText9(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <input className="form-control" type="text" value={chargeScreenText10} id="input-charge-screen-text" onChange={(e) => setChargeScreenText10(e.target.value)} />
                          </div>
                        </div>
                      </Tab>
                      :
                      <></>
                    }

                  </Tabs>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={toggleShowDeleteModal}>
                  Delete
                </Button>
                <Button variant="secondary" onClick={props.toggleShowEditModal}>
                  Close
                </Button>
                <Button variant="primary" type="submit" form="update_campaign_form">
                  Save
                </Button>
              </Modal.Footer>
            </Modal>

          )
        }
      })()}

      <DeleteModal toggleShowDeleteModal={toggleShowDeleteModal} showDeleteModal={showDeleteModal} deleteId={props.editId} resourceType={"CAMPAIGN"} toggleCampaignEditModal={props.toggleShowEditModal} />

    </>
  )
}
