import React from "react";
import TopBar from "../sections/TopBar";
import Menu from "../sections/Menu";
import { Form } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

const keySpanStyle = {
	background: "lightgrey",
	width: "100%",
	display: "block",
	borderRadius: "5px",
	textAlign: "center",
	height: "2rem",
	lineHeight: "2rem",
};

export default function ApiKeys() {
	const { user } = useSelector((state) => state);
	const [currentToken, setCurrentToken] = useState(null);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const generateKey = async () => {
		const response = await axios
			.get(process.env.REACT_APP_ENV_URL + "/api/key/generate", {
				headers: {
					Authorization: "Bearer " + user.token,
				},
			})
			.catch((error) => {
				console.log(error);
			});

		if ("token" in response.data) {
			toast.success("Success", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			setCurrentToken(response.data.token);
			handleShow();
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Generate API Key
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item active">
												<a>API Keys</a>
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<Form id="generate_key_form">
												<div className="form-group row m-t-30">
													<div className="col-sm-8">
														<h6>
															Generating a key
															will disable any
															previous keys. Once
															you create your key,
															make sure to store
															it somewhere, it
															will only be shown
															once.
														</h6>
														<button
															type="submit"
															style={{
																width: "8rem",
																marginTop:
																	"2rem",
															}}
															className="btn btn-success"
															onClick={() =>
																generateKey()
															}
														>
															Generate Key
														</button>
													</div>
													<div className="col-sm-4"></div>
												</div>
											</Form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>

				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>API Key</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span style={keySpanStyle}>{currentToken}</span>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
}
