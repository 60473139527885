import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Form, Tabs, Tab } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { updateCharger } from '../../features/ChargersSlice'

export default function EditChargerModal(props) {
	const dispatch = useDispatch()
	const chargers = useSelector((state) => state.chargers.chargers)
	const stations = useSelector((state) => state.stations)
	const user = useSelector((state) => state.user)
	const [charger, setCharger] = useState(null)
	const [disableSubmit, setDisableSubmit] = useState(false)

	const saveCharger = async (e) => {
		e.preventDefault()
		setDisableSubmit(true)

		const formData = new FormData()

		formData.append('token', user.token)
		formData.append('charger_id', charger.id)
		formData.append('station_id', charger.station_id)
		formData.append('serial_no', charger.serial_no)
		formData.append('id_tag', charger.id_tag)
		formData.append('status', charger.status)
		formData.append('EVSE_power_limit_W', charger.EVSE_power_limit_W)
		formData.append('EVSE_current_limit_mA', charger.EVSE_current_limit_mA)
		formData.append('EVSE_voltage_limit_mV', charger.EVSE_voltage_limit_mV)
		formData.append('EV_power_limit_W', charger.EV_power_limit_W)
		formData.append('EV_current_limit_mA', charger.EV_current_limit_mA)
		formData.append('EV_voltage_limit_mV', charger.EV_voltage_limit_mV)
		formData.append('SOC', charger.SOC)
		formData.append('start_SOC', charger.start_SOC)
		formData.append('stop_SOC', charger.stop_SOC)
		formData.append('iotecha_username', charger.iotecha_username)
		formData.append('iotecha_password', charger.iotecha_password)
		formData.append('ip_address', charger.ip_address)

		const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/charger/update', formData, {
			headers: {
				Authorization: 'Bearer ' + user.token
			}
		}).catch((error) => {
			toast.error('Something went wrong', {
				position: "top-right",
				autoClose: 8000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		})

		setTimeout(() => {
			setDisableSubmit(false)
		}, 3000)

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			dispatch(updateCharger(response.data.data))
			props.toggleShowEditModal()

		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	}

	useEffect(() => {
		/* Get charger from state */
		let charger = chargers.find(obj => {
			return obj.id == props.editId
		})

		setCharger(charger)
	}, [props.editId])

	return (
		<>
			<Modal show={props.showEditModal} onHide={props.toggleShowEditModal} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Edit Charger</Modal.Title>
				</Modal.Header>
				<Form id="edit_charger_form" onSubmit={saveCharger}>
					<Modal.Body>
						{(() => {
							if (charger) {
								return (
									<Tabs defaultActiveKey="details" className="mb-3">
										<Tab eventKey="details" title="Details">
											<div className="row">
												<div className="col-12">
													<div className="card m-b-30">
														<div className="card-body">
															<div className="form-group row">
																<div className="col-sm-6">
																	<label>Station</label>
																	<select className="form-control" value={charger.station_id} onChange={(e) => { setCharger({ ...charger, station_id: e.target.value }) }}>
																		<option>Select a Station</option>
																		{
																			stations.stations.map((station, index) => (
																				<option key={index} value={station.id}>{station.name}</option>
																			))
																		}
																	</select>
																</div>
																<div className="col-sm-6">
																	<label>Serial #</label>
																	<input className="form-control" type="text" id="input-serial-no" value={charger.serial_no === 0 ? '' : charger.serial_no} onChange={(e) => { setCharger({ ...charger, serial_no: e.target.value }) }} />
																</div>
															</div>
															<div className="form-group row m-t-30">
																<div className="col-sm-6">
																	<label>ID Tag</label>
																	<input className="form-control" type="text" id="input-id-tag" value={charger.id_tag} onChange={(e) => { setCharger({ ...charger, id_tag: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Tab>
										<Tab eventKey="limits" title="Limits">
											<div className="row">
												<div className="col-12">
													<div className="card m-b-30">
														<div className="card-body">
															<div className="form-group row">
																<div className="col-sm-6">
																	<label>EVSE Power Limit (W)</label>
																	<input className="form-control" type="number" id="input-evse-power-limit" value={charger.EVSE_power_limit_W === 0 ? '' : charger.EVSE_power_limit_W} onChange={(e) => { setCharger({ ...charger, EVSE_power_limit_W: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																	<label>EVSE Current Limit (mA)</label>
																	<input className="form-control" type="number" id="input-evse-current-limit" value={charger.EVSE_current_limit_mA === 0 ? '' : charger.EVSE_current_limit_mA} onChange={(e) => { setCharger({ ...charger, EVSE_current_limit_mA: e.target.value }) }} />
																</div>
															</div>
															<div className="form-group row m-t-30">
																<div className="col-sm-6">
																	<label>EVSE Voltage Limit (mV)</label>
																	<input className="form-control" type="number" id="input-evse-voltage-limit" value={charger.EVSE_voltage_limit_mV === 0 ? '' : charger.EVSE_voltage_limit_mV} onChange={(e) => { setCharger({ ...charger, EVSE_voltage_limit_mV: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																	<label>EV Power Limit (W)</label>
																	<input className="form-control" type="number" id="input-ev-power-limit" value={charger.EV_power_limit_W === 0 ? '' : charger.EV_power_limit_W} onChange={(e) => { setCharger({ ...charger, EV_power_limit_W: e.target.value }) }} />
																</div>
															</div>
															<div className="form-group row m-t-30">
																<div className="col-sm-6">
																	<label>EV Current Limit (mA)</label>
																	<input className="form-control" type="number" id="input-ev-current-limit" value={charger.EV_current_limit_mA === 0 ? '' : charger.EV_current_limit_mA} onChange={(e) => { setCharger({ ...charger, EV_current_limit_mA: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																	<label>EV Voltage Limit (mV)</label>
																	<input className="form-control" type="number" id="input-ev-voltage-limit" value={charger.EV_voltage_limit_mV === 0 ? '' : charger.EV_voltage_limit_mV} onChange={(e) => { setCharger({ ...charger, EV_voltage_limit_mV: e.target.value }) }} />
																</div>
															</div>
															<div className="form-group row m-t-30">
																<div className="col-sm-6">
																	<label>SOC</label>
																	<input className="form-control" type="number" id="input-soc" value={charger.SOC === 0 ? '' : charger.SOC} onChange={(e) => { setCharger({ ...charger, SOC: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																	<label>Start SOC</label>
																	<input className="form-control" type="number" id="input-start-soc" value={charger.start_SOC === 0 ? '' : charger.start_SOC} onChange={(e) => { setCharger({ ...charger, start_SOC: e.target.value }) }} />
																</div>
															</div>
															<div className="form-group row m-t-30">
																<div className="col-sm-6">
																	<label>Stop SOC</label>
																	<input className="form-control" type="number" id="input-stop-soc" value={charger.stop_SOC === 0 ? '' : charger.stop_SOC} onChange={(e) => { setCharger({ ...charger, stop_SOC: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Tab>
										<Tab eventKey="iotecha" title="Iotecha">
											<div className="row">
												<div className="col-12">
													<div className="card m-b-30">
														<div className="card-body">
															<div className="form-group row">
																<div className="col-sm-6">
																	<label>Username</label>
																	<input className="form-control" type="text" id="input-iotecha-username" value={charger.iotecha_username} onChange={(e) => { setCharger({ ...charger, iotecha_username: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																	<label>Password</label>
																	<input className="form-control" type="text" id="input-iotecha-password" value={charger.iotecha_password} onChange={(e) => { setCharger({ ...charger, iotecha_password: e.target.value }) }} />
																</div>
															</div>
															<div className="form-group row">
																<div className="col-sm-6">
																	<label>Ip Address</label>
																	<input className="form-control" type="text" id="input-ip-address" value={charger.ip_address} onChange={(e) => { setCharger({ ...charger, ip_address: e.target.value }) }} />
																</div>
																<div className="col-sm-6">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Tab>
									</Tabs>
								)
							}
						})()}
					</Modal.Body>
					<Modal.Footer>
						<button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowEditModal}>Close</button>
						<button disabled={disableSubmit} type="submit" className="btn btn-success waves-effect waves-light">Save changes</button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}
