import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getMerchantSettings = createAsyncThunk('merchantSettings/getSettings', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/merchant/settings', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const merchantSettingsSlice = createSlice({
  name: 'merchantSettings',
  initialState: {
    settings: {},
    loading: ''
  },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMerchantSettings.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getMerchantSettings.fulfilled, (state, action) => {
      state.settings = action.payload
      state.loading = ''
    })
    builder.addCase(getMerchantSettings.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setSettings } = merchantSettingsSlice.actions

export default merchantSettingsSlice.reducer