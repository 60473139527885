import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getChargeSessions = createAsyncThunk('chargesessions/getSessions', async () => {
  const user = store.getState().user;

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/charger/orders', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const chargeSessionsSlice = createSlice({
  name: 'chargeSessions',
  initialState: {
    sessions: [],
    loading: ''
  },
  reducers: {
    setSessions: (state, action) => {
      state.sessions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChargeSessions.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getChargeSessions.fulfilled, (state, action) => {
      state.sessions = action.payload
      state.loading = ''
    })
    builder.addCase(getChargeSessions.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setSessions } = chargeSessionsSlice.actions

export default chargeSessionsSlice.reducer