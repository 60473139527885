import React from 'react';
import ReactDOM from 'react-dom/client';
import Dashboard from './components/pages/Dashboard';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux';
import Login from './components/pages/Login';
import { createBrowserRouter, RouterProvider, Route, Link, redirect } from "react-router-dom";
import { getUser, setToken } from './features/UserSlice';
import Stations from './components/pages/Stations';
import Register from './components/pages/Register';
import { ToastContainer } from 'react-toastify';
import Chargers from './components/pages/Chargers';
import Devices from './components/pages/Devices';
import Campaigns from './components/pages/Campaigns';
import { setPage } from './features/ActivePageSlice';
import { getChargeSessions } from './features/ChargeSessionsSlice';
import { getStations } from './features/StationsSlice';
import { getNotifications } from './features/NotificationsSlice';
import Invoice from './components/pages/Invoice';
import Customers from './components/pages/Customers';
import NotFound404 from './components/pages/NotFound404';
import Support from './components/pages/Support';
import Media from './components/pages/Media';
import Profile from './components/pages/Profile';
import Settings from './components/pages/Settings';
import ApiKeys from './components/pages/ApiKeys';
import Users from './components/pages/Users';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import CancelledCampaigns from './components/pages/CancelledCampaigns';
import Notifications from './components/pages/Notifications';
import { is } from 'date-fns/locale';
import CompleteRegistration from './components/pages/CompleteRegistration';

const isLoggedIn = () => {
  if (localStorage.getItem('ctc_token') === null) {
    return false
  } else {
    return true;
  }
}

/**
 * Checks if current user is a developer. If user is developer
 * redirect to the APIKeys page
 */
const userIsDeveloper = (user) => {
  if (user.api_user == 1) {
    return true;
  } else {
    return false;
  }
}

const isUserRole = (user, roles) => {
  if (roles.includes(user.role)) {
    return true;
  } else {
    return false;
  }
}

/**
 * If token is invalid than delete from localStorage and redirect to login
 * Logging in will generate a new token
 */
const tokenIsInvalid = (result) => {
  if (result.error?.message?.includes("401")) {
    return true
  } else {
    return false
  }
}

const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <NotFound404 />
    )
  },
  {
    path: "/support",
    element: (
      <Support />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        store.dispatch(setPage([...['SUPPORT']]))
        return <Support />;
      }
    }
  },
  {
    path: "/",
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return redirect('/dashboard')
      }
    }
  },
  {
    path: "/login",
    element: (
      <Login />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return <Login />
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return redirect('/dashboard');
      }
    }
  },
  {
    path: "/forgot-password",
    element: (
      <ForgotPassword />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return <ForgotPassword />
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return redirect('/dashboard');
      }
    }
  },
  {
    path: "/reset-password",
    element: (
      <ResetPassword />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return <ResetPassword />
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return redirect('/dashboard');
      }
    }
  },
  {
    path: "/register",
    element: (
      <Register />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return <Register />
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return redirect('/dashboard');
      }
    }
  },
  {
    path: "/complete-registration",
    element: (
      <CompleteRegistration />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return <CompleteRegistration />
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return redirect('/dashboard');
      }
    }
  },
  {
    path: "/dashboard",
    element: (
      <Dashboard />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        store.dispatch(setPage([...['DASHBOARD', '']]))
        store.dispatch(getChargeSessions())
        store.dispatch(getStations())
        return <Dashboard />;
      }
    }
  },
  {
    path: "/stations",
    element: (
      <Stations />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        if (!isUserRole(user, ['ADMIN', 'SUPER ADMIN'])) {
          return redirect('/dashboard')
        }

        store.dispatch(setPage([...['MANAGEMENT', 'STATIONS']]))
        return <Stations />;
      }
    }
  },
  {
    path: "/chargers",
    element: (
      <Chargers />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        if (!isUserRole(user, ['ADMIN', 'SUPER ADMIN'])) {
          return redirect('/dashboard')
        }

        store.dispatch(setPage([...['MANAGEMENT', 'CHARGERS']]))
        return <Chargers />;
      }
    }
  },
  {
    path: "/devices",
    element: (
      <Devices />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        if (!isUserRole(user, ['ADMIN', 'SUPER ADMIN'])) {
          return redirect('/dashboard')
        }

        store.dispatch(setPage([...['MANAGEMENT', 'DEVICES']]))
        return <Devices />;
      }
    }
  },

  {
    path: "/media",
    element: (
      <Media />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      }

      store.dispatch(setToken(localStorage.getItem('ctc_token')))

      let result = await store.dispatch(getUser())
      let user = result.payload

      if (tokenIsInvalid(result)) {
        localStorage.removeItem('ctc_token')
        return redirect('/login')
      }

      if (userIsDeveloper(user)) {
        return redirect('/apikeys')
      }
      if (!isUserRole(user, ['SUPER ADMIN', 'ADMIN', 'CAMPAIGN MANAGER'])) {
        return redirect('/dashboard')
      }
      store.dispatch(setPage([...['ADVERTISING', 'MEDIA']]))
      return <Media />

    }
  },
  {
    path: "/campaigns",
    element: (
      <Campaigns />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        if (!isUserRole(user, ['SUPER ADMIN', 'ADMIN', 'CAMPAIGN MANAGER'])) {
          return redirect('/dashboard')
        }

        store.dispatch(setPage([...['ADVERTISING', 'CAMPAIGNS']]))
        return <Campaigns />;
      }
    }
  },
  {
    path: "/cancelled",
    element: (
      <CancelledCampaigns />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        if (!isUserRole(user, ['SUPER ADMIN', 'ADMIN', 'CAMPAIGN MANAGER'])) {
          return redirect('/dashboard')
        }

        store.dispatch(setPage([...['ADVERTISING', 'CANCELLED CAMPAIGNS']]))
        return <CancelledCampaigns />;
      }
    }
  },
  {
    path: "/notifications",
    element: (
      <Notifications />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        store.dispatch(setPage([...['', '']]))
        return <Notifications />;
      }
    }
  },
  {
    path: "/invoice",
    element: (
      <Invoice />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        store.dispatch(setPage([...['DASHBOARD', '']]))
        return <Invoice />;
      }
    }
  },
  {
    path: "/customers",
    element: (
      <Customers />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        if (!isUserRole(user, ['SUPER ADMIN'])) {
          return redirect('/dashboard')
        }

        store.dispatch(setPage([...['MANAGEMENT', 'CUSTOMERS']]))
        return <Customers />;
      }
    }
  },
  {
    path: "/profile",
    element: (
      <Profile />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return <Profile />;
      }
    }
  },
  {
    path: "/settings",
    element: (
      <Settings />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }

        return <Settings />;
      }
    }
  },
  {
    path: "/users",
    element: (
      <Users />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }

        if (userIsDeveloper(user)) {
          return redirect('/apikeys')
        }
        store.dispatch(setPage([...['USERS']]))
        return <Users />;
      }
    }
  },
  {
    path: "/apikeys",
    element: (
      <ApiKeys />
    ),
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect('/login')
      } else {
        store.dispatch(setToken(localStorage.getItem('ctc_token')))
        store.dispatch(getUser())

        let result = await store.dispatch(getUser())
        let user = result.payload

        if (tokenIsInvalid(result)) {
          localStorage.removeItem('ctc_token')
          return redirect('/login')
        }
        if (!userIsDeveloper(user)) {
          return redirect('dashboard')
        }

        return <ApiKeys />;
      }
    }
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
