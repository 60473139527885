import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import axios from 'axios'
import { updateDevice } from '../../features/DevicesSlice'
import StateOptions from '../sections/StateOptions'
import { updateCustomer } from '../../features/CustomersSlice'

export default function EditCustomerModal(props) {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.customers)
  const user = useSelector((state) => state.user)
  const [customer, setCustomer] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const saveCustomer = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('customer_id', customer.id)
    formData.append('company_name', customer.company_name)
    formData.append('email', customer.email)
    formData.append('address', customer.address)
    formData.append('city', customer.city)
    formData.append('state', customer.state)
    formData.append('zipcode', customer.zipcode)
    formData.append('telephone', customer.telephone)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/customer/update', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(updateCustomer(response.data.data))
      props.toggleShowEditModal()

    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {
    /* Get customer from state */
    let customer = customers.find(obj => {
      return obj.id == props.editId
    })

    setCustomer(customer)
  }, [props.editId])

  return (
    <Modal show={props.showEditModal} onHide={props.toggleShowEditModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          if (customer) {
            return (
              <Form id="edit_customer_form" onSubmit={saveCustomer}>
                {/* <Tabs defaultActiveKey="details" className="mb-3">
                  <Tab eventKey="details" title="Details"> */}
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Company Name</label>
                            <input className="form-control" type="text" id="input-company-name" value={customer.company_name} onChange={(e) => { setCustomer({ ...customer, company_name: e.target.value }) }} />
                          </div>
                          <div className="col-sm-6">
                            <label>Email</label>
                            <input className="form-control" type="text" id="input-email" value={customer.email} onChange={(e) => { setCustomer({ ...customer, email: e.target.value }) }} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>Address</label>
                            <input className="form-control" type="text" id="input-address" value={customer.address} onChange={(e) => { setCustomer({ ...customer, address: e.target.value }) }} />
                          </div>
                          <div className="col-sm-6">
                            <label>City</label>
                            <input className="form-control" type="text" id="input-city" value={customer.city} onChange={(e) => { setCustomer({ ...customer, city: e.target.value }) }} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>State</label>
                            <select className="form-control" value={customer.state} onChange={(e) => { setCustomer({ ...customer, state: e.target.value }) }}>
                              <StateOptions />
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Zipcode</label>
                            <input className="form-control" type="text" id="input-zip" value={customer.zipcode} onChange={(e) => { setCustomer({ ...customer, zipcode: e.target.value }) }} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>Telephone</label>
                            <input className="form-control" type="text" id="input-telephone" value={customer.telephone} onChange={(e) => { setCustomer({ ...customer, telephone: e.target.value }) }} />
                          </div>
                          <div className="col-sm-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </Tab>
                <Tab eventKey="logo" title="Logo">
                  <div className="row">
                    <div className="col-12">
                      <div className="card m-b-30">
                        <div className="card-body">
                          Unavailable at the moment.
                        </div>
                      </div>
                    </div>
                  </div>
                  </Tab>
                </Tabs> */}
              </Form>
            )
          } else {
            return (
              <></>
            )
          }
        })()}

      </Modal.Body>
      <Modal.Footer>
        <button disabled={disableSubmit} type="submit" form="edit_customer_form" className="btn btn-success waves-effect waves-light">Save changes</button>
      </Modal.Footer>
    </Modal>
  )
}
