import { createSlice } from "@reduxjs/toolkit"

export const activePageSlice = createSlice({
  name: 'activePage',
  initialState: {
    /**
     * Use section of menu
     * 
     * ['DASHBOARD']
     * ['SUPPORT']
     * ['MANAGEMENT', 'STATIONS']
     * ['MANAGEMENT', 'DEVICES']
     * ['MANAGEMENT', 'DEVICES']
     * ['MANAGEMENT', 'CUSTOMERS']
     * ['ADVERTISING', 'CAMPAIGNS']
     */
    page: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
  },
})

export const { setPage } = activePageSlice.actions

export default activePageSlice.reducer