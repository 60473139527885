import React from 'react'
import NavbarRight from './NavbarRight'
import MobileMenuButton from '../buttons/MobileMenuButton'

export default function TopBar() {
  return (
    <div className="topbar">

      <div className="topbar-left"></div>

      <nav className="navbar-custom">
        <NavbarRight />

        <ul className="list-inline menu-left mb-0">
          <li className="float-left">
            <MobileMenuButton />
          </li>
          <li className="d-none d-md-inline-block">
            <form role="search" className="app-search">
              <div className="form-group mb-0">
                <img src="assets/images/logo-color-tm.jpg" width="325px" style={{ marginTop: ".2rem" }} className="img-thumbnails" />
              </div>
            </form>
          </li>
        </ul>

      </nav>

    </div>
  )
}
