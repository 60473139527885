/* global Waves */
import React, { useReducer, useEffect, useState } from 'react'

export default function useScriptImporter() {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [scriptsImported, setScriptsImported] = useState(false);

  useEffect(() => { 
    if (scriptsImported === false) {
      /**
       * This will include the scripts in this order. It will check if it's undefined and if it
       * is it will add it and force a re-render and check again before moving on to the next one.
       * This resolved issues with some scripts missing things from other scripts.
       */
      if (typeof window.$ === 'undefined') {
        const jq = document.createElement("script");
        jq.src = "assets/js/jquery.min.js";
        document.body.appendChild(jq);

        forceUpdate();
        return;
      }

      if (window.bootstrapBundle === undefined) {
        const bootstrapBundle = document.createElement("script");
        bootstrapBundle.src = "assets/js/bootstrap.bundle.min.js";
        document.body.appendChild(bootstrapBundle);

        forceUpdate();
        return;
      }

      if (window.waves === undefined) {
        const wavesScript = document.createElement("script");
        wavesScript.src = "assets/js/waves.min.js";
        document.body.appendChild(wavesScript);

        forceUpdate();
        return;
      }

      Waves.init();
      setScriptsImported(true);
    }
  })

  return scriptsImported;
}
