import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/UserSlice'
import stationsReducer from './features/StationsSlice'
import chargersReducer from './features/ChargersSlice'
import devicesReducer from './features/DevicesSlice'
import mediaReducer from './features/MediaSlice'
import campaignsReducer from './features/CampaignsSlice'
import activePageReducer from './features/ActivePageSlice'
import chargeSessionsReducer from './features/ChargeSessionsSlice'
import notificationsReducer from './features/NotificationsSlice'
import NotificationsSlice from './features/NotificationsSlice'
import customersReducer from './features/CustomersSlice'
import merchantSettingsReducer from './features/MerchantSettingsSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    stations: stationsReducer,
    chargers: chargersReducer,
    devices: devicesReducer,
    media: mediaReducer,
    campaigns: campaignsReducer,
    activePage: activePageReducer,
    chargeSessions: chargeSessionsReducer,
    notifications: notificationsReducer,
    customers: customersReducer,
    merchantSettings: merchantSettingsReducer
  },
})