/* eslint-disable */
import React, { useState, useEffect } from "react";
import Menu from "../sections/Menu";
import MobileMenuButton from "../buttons/MobileMenuButton";
import NavbarRight from "../sections/NavbarRight";
import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-bs4";
import useScriptImporter from "../hooks/useScriptImporter";
import { useSelector, useDispatch } from "react-redux";
import AddDeviceModal from "../modals/AddDeviceModal";
import { getDevices } from "../../features/DevicesSlice";
import ActiveBadge from "../sections/StatusTD";
import StatusTD from "../sections/StatusTD";
import EditDeviceModal from "../modals/EditDeviceModal";
import DeleteModal from "../modals/DeleteModal";
import AddCustomerModal from "../modals/AddCustomerModal";
import { clearCustomers, getCustomers } from "../../features/CustomersSlice";
import EditCustomerModal from "../modals/EditCustomerModal";
import TopBar from "../sections/TopBar";

DataTable.Buttons.pdfMake(pdfMake);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Customers() {
	const scriptsImported = useScriptImporter();
	const customers = useSelector((state) => state.customers.customers);
	const [customerList, setCustomerList] = useState([]);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const [showAddModal, setShowAddModal] = useState(false);
	const [keyIncrementer, setKeyIncrementer] = useState(0);
	const [editId, setEditId] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState(false);
	const targetColumns = user.data.role == "SUPER ADMIN" ? [-1, -2] : [-1];

	const toggleShowAddModal = () => {
		setShowAddModal(!showAddModal);
	};

	const toggleShowEditModal = () => {
		setShowEditModal(!showEditModal);
	};

	const toggleShowDeleteModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	useEffect(() => {
		dispatch(getCustomers());
		return () => {
			dispatch(clearCustomers());
		};
	}, []);

	useEffect(() => {
		$("#datatable").DataTable({
			pageLength: 10,
			lengthChange: true,
			lengthMenu: [10, 20, 50, 100, 200, 500],
			dom: "Blfrtip",
			columnDefs: [
				{
					targets: targetColumns,
					className: "all",
				},
			],
			buttons: [
				{
					extend: "copyHtml5",
					filename: "customers",
					title: "Customers",
				},
				{
					extend: "excelHtml5",
					filename: "customers",
					title: "Customers",
				},
				{
					extend: "csvHtml5",
					filename: "customers",
					title: "Customers",
				},
				{
					extend: "pdfHtml5",
					filename: "customers",
					title: "Customers",
					orientation: "landscape",
				},
			],
		});
	}, [keyIncrementer]);
	console.log(customers);

	useEffect(() => {
		/**
		 * Using a key and an incrementing it when this state
		 * changes will cause the element to be removed and
		 * re-rendered, this way we can refresh the datatable correctly
		 */
		setCustomerList(customers);
		setKeyIncrementer(keyIncrementer + 1);
	}, [customers]);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Customers
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Customers</a>
											</li>
											<li className="breadcrumb-item active">
												Listing
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a listing of all
												customers per account.
												<span
													style={{ float: "right" }}
												>
													<a
														className="btn btn-success btn-md"
														style={{
															color: "white",
														}}
														onClick={() =>
															toggleShowAddModal()
														}
													>
														<i className="fa fa-plus"></i>{" "}
														Add
													</a>
												</span>
											</p>
											<div key={keyIncrementer}>
												<table
													id="datatable"
													className="table table-bordered dt-responsive nowrap"
													style={{
														borderCollapse:
															"collapse",
														borderSpacing: 0,
														width: "100%",
													}}
												>
													<thead>
														<tr>
															<th>Company</th>
															<th>Email</th>
															<th>Address</th>
															<th>City</th>
															<th>State</th>
															<th>Zipcode</th>
															<th>Telephone</th>
															<th>Edit</th>
															{user.data.role ==
															"SUPER ADMIN" ? (
																<th>Delete</th>
															) : (
																<> </>
															)}
														</tr>
													</thead>

													<tbody>
														{customerList.map(
															(
																customer,
																index
															) => {
																return (
																	<tr
																		key={
																			index
																		}
																	>
																		<td>
																			{
																				customer.company_name
																			}
																		</td>
																		<td>
																			{
																				customer.email
																			}
																		</td>
																		<td>
																			{
																				customer.address
																			}
																		</td>
																		<td>
																			{
																				customer.city
																			}
																		</td>
																		<td>
																			{
																				customer.state
																			}
																		</td>
																		<td>
																			{
																				customer.zipcode
																			}
																		</td>
																		<td>
																			{
																				customer.telephone
																			}
																		</td>
																		<td
																			style={{
																				textAlign:
																					"center",
																			}}
																		>
																			<button
																				type="button"
																				className="btn btn-primary"
																				onClick={() => {
																					toggleShowEditModal();
																					setEditId(
																						customer.id
																					);
																				}}
																			>
																				<i className="mdi mdi-pencil text-white"></i>
																			</button>
																		</td>
																		{user
																			.data
																			.role ==
																		"SUPER ADMIN" ? (
																			<td
																				style={{
																					textAlign:
																						"center",
																				}}
																			>
																				<button
																					type="button"
																					className="btn btn-danger"
																					onClick={() => {
																						toggleShowDeleteModal();
																						setDeleteId(
																							customer.id
																						);
																					}}
																				>
																					<i className="mdi mdi-trash-can-outline text-white"></i>
																				</button>
																			</td>
																		) : (
																			<>

																			</>
																		)}
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<AddCustomerModal
						toggleShowAddModal={toggleShowAddModal}
						showAddModal={showAddModal}
					/>
					<EditCustomerModal
						toggleShowEditModal={toggleShowEditModal}
						showEditModal={showEditModal}
						editId={editId}
					/>
					<DeleteModal
						toggleShowDeleteModal={toggleShowDeleteModal}
						showDeleteModal={showDeleteModal}
						deleteId={deleteId}
						resourceType={"CUSTOMER"}
					/>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
