import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux'
import { Form, Tabs, Tab } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import StateOptions from '../sections/StateOptions';
import { getCustomers } from '../../features/CustomersSlice';

export default function AddCustomerModal(props) {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [telephone, setTelephone] = useState('')
  const [logo, logoPath] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)

  const createCustomer = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('company_name', companyName)
    formData.append('email', email)
    formData.append('address', address)
    formData.append('city', city)
    formData.append('state', state)
    formData.append('zipcode', zipcode)
    formData.append('telephone', telephone)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/customer/create', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      console.log(error)
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(getCustomers())
      props.toggleShowAddModal()
    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  return (
    <>
      <Modal show={props.showAddModal} onHide={props.toggleShowAddModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add A New Customer</Modal.Title>
        </Modal.Header>
        <Form id="create_customer_form" onSubmit={createCustomer}>
          <Modal.Body>
            {/* <Tabs defaultActiveKey="details" className="mb-3">
              <Tab eventKey="details" title="Details"> */}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label>Company Name</label>
                        <input className="form-control" type="text" id="input-company-name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                        <label>Email</label>
                        <input className="form-control" type="text" id="input-email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row m-t-30">
                      <div className="col-sm-6">
                        <label>Address</label>
                        <input className="form-control" type="text" id="input-address" value={address} onChange={(e) => setAddress(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                        <label>City</label>
                        <input className="form-control" type="text" id="input-city" value={city} onChange={(e) => setCity(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row m-t-30">
                      <div className="col-sm-6">
                        <label>State</label>
                        <select className="form-control" onChange={(e) => setState(e.target.value)}>
                          <StateOptions />
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label>Zipcode</label>
                        <input className="form-control" type="text" id="input-zip" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row m-t-30">
                      <div className="col-sm-6">
                        <label>Telephone</label>
                        <input className="form-control" type="text" id="input-telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </Tab> 
            <Tab eventKey="logo" title="Logo">
              <div className="row">
                <div className="col-12">
                  <div className="card m-b-30">
                    <div className="card-body">
                      Unavailable at the moment.
                    </div>
                  </div>
                </div>
              </div>
            </Tab> 
            </Tabs> */}
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowAddModal}>Close</button>
            <button disabled={disableSubmit} type="submit" className="btn btn-success waves-effect waves-light">Save changes</button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
