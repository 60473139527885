import React from "react";
import TopBar from "../sections/TopBar";
import Menu from "../sections/Menu";
import StateOptions from "../sections/StateOptions";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default function Profile() {
	const user = useSelector((state) => state.user);
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [telephone, setTelephone] = useState("");

	const updateUser = async () => {
		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("name", name);
		formData.append("address", address);
		formData.append("city", city);
		formData.append("state", state);
		formData.append("zipcode", zipcode);
		formData.append("telephone", telephone);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/user/update",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	useEffect(() => {
		setName(user.data.name);
		setAddress(user.data.address);
		setCity(user.data.city);
		setState(user.data.state);
		setZipcode(user.data.zipcode);
		setTelephone(user.data.telephone);
	}, []);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Profile
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>User</a>
											</li>
											<li className="breadcrumb-item active">
												Profile
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<div className="form-group row m-t-30">
												<div className="col-sm-4">
													<label>Email</label>
													<div>{user.data.email}</div>
												</div>
											</div>
											<div className="form-group row m-t-30">
												<div className="col-sm-4">
													<label>Name</label>
													<input
														className="form-control"
														type="text"
														id="input-name"
														value={name}
														onChange={(e) =>
															setName(
																e.target.value
															)
														}
													/>
												</div>
												<div className="col-sm-4">
													<label>Address</label>
													<input
														className="form-control"
														type="text"
														id="input-address"
														value={address}
														onChange={(e) =>
															setAddress(
																e.target.value
															)
														}
													/>
												</div>
											</div>
											<div className="form-group row m-t-30">
												<div className="col-sm-4">
													<label>City</label>
													<input
														className="form-control"
														type="text"
														id="input-city"
														value={city}
														onChange={(e) =>
															setCity(
																e.target.value
															)
														}
													/>
												</div>
												<div className="col-sm-4">
													<label>State</label>
													<select className="form-control">
														<StateOptions
															value={state}
															onChange={(e) =>
																setState(
																	e.target
																		.value
																)
															}
														/>
													</select>
												</div>
											</div>
											<div className="form-group row m-t-30">
												<div className="col-sm-4">
													<label>Zipcode</label>
													<input
														className="form-control"
														type="text"
														id="input-zipcode"
														value={zipcode}
														onChange={(e) =>
															setZipcode(
																e.target.value
															)
														}
													/>
												</div>
												<div className="col-sm-4">
													<label>Telephone</label>
													<input
														className="form-control"
														type="text"
														id="input-address"
														value={telephone}
														onChange={(e) =>
															setTelephone(
																e.target.value
															)
														}
													/>
												</div>
											</div>
											<div className="form-group row m-t-30">
												<div className="col-sm-4"></div>
												<div className="col-sm-4">
													<button
														style={{
															width: "5rem",
															marginTop: "2rem",
														}}
														className="btn btn-success float-right"
														onClick={() =>
															updateUser()
														}
													>
														Save
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
