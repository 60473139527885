import React from 'react'

export default function NotFound404() {
  return (
    <>
      <div className="error-bg"></div>
      <div className="home-btn d-none d-sm-block"></div>

      <div className="account-pages">

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="card shadow-lg">
                <div className="card-block">
                  <div className="text-center p-3">
                    <h1 className="error-page mt-4"><span>404!</span></h1>
                    <h4 className="mb-4 mt-5">Sorry, page not found</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
