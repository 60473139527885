/* eslint-disable */
import React, { useState, useEffect } from "react";
import Menu from "../sections/Menu";
import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-bs4";
import useScriptImporter from "../hooks/useScriptImporter";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../sections/TopBar";
import { getAllNotifications } from "../../features/NotificationsSlice";
const dayjs = require("dayjs");

DataTable.Buttons.pdfMake(pdfMake);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Notifications() {
	const scriptsImported = useScriptImporter();
	const allNotifications = useSelector(
		(state) => state.notifications.fullList
	);
	const dispatch = useDispatch();
	const [keyIncrementer, setKeyIncrementer] = useState(0);

	useEffect(() => {
		dispatch(getAllNotifications());
	}, []);

	useEffect(() => {
		$("#datatable").DataTable({
			pageLength: 10,
			lengthChange: true,
			lengthMenu: [10, 20, 50, 100, 200, 500],
			dom: "Blfrtip",
			buttons: ["copyHtml5", "excelHtml5", "csvHtml5", "pdfHtml5"],
		});
	}, [keyIncrementer]);

	useEffect(() => {
		/**
		 * Using a key and an incrementing it when this state
		 * changes will cause the element to be removed and
		 * re-rendered, this way we can refresh the datatable correctly
		 */
		setKeyIncrementer(keyIncrementer + 1);
	}, [allNotifications]);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Notifications
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Notifications</a>
											</li>
											<li className="breadcrumb-item active">
												View All
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a listing of all
												notifications per account
												<span
													style={{ float: "right" }}
												></span>
											</p>
											<div key={keyIncrementer}>
												<table
													id="datatable"
													className="table table-bordered dt-responsive nowrap"
													style={{
														borderCollapse:
															"collapse",
														borderSpacing: 0,
														width: "100%",
													}}
												>
													<thead>
														<tr>
															<th>Type</th>
															<th>Level</th>
															<th>Message</th>
															<th>Log Time</th>
														</tr>
													</thead>

													<tbody>
														{allNotifications.map(
															(
																notification,
																index
															) => {
																return (
																	<tr
																		key={
																			index
																		}
																	>
																		<td>
																			{notification.type
																				.split(
																					"\\"
																				)[2]
																				.replace(
																					"Error",
																					""
																				)}
																		</td>
																		{(() => {
																			if (
																				notification
																					.data
																					.level ==
																				"info"
																			) {
																				return (
																					<td className="btn-info">
																						{notification.data.level.toUpperCase()}
																					</td>
																				);
																			} else if (
																				notification
																					.data
																					.level ==
																				"warning"
																			) {
																				return (
																					<td className="btn-warning">
																						{notification.data.level.toUpperCase()}
																					</td>
																				);
																			} else if (
																				notification
																					.data
																					.level ==
																				"critical"
																			) {
																				return (
																					<td className="btn-danger">
																						{notification.data.level.toUpperCase()}
																					</td>
																				);
																			} else {
																				return (
																					<td>
																						{notification.data.level.toUpperCase()}
																					</td>
																				);
																			}
																		})()}

																		<td>
																			{
																				notification
																					.data
																					.message
																			}
																		</td>
																		<td>
																			{dayjs(
																				notification.created_at
																			).format(
																				"MM/DD/YYYY h:mm a"
																			)}
																		</td>
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
