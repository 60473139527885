import React from 'react'
import $ from 'jquery'

export default function MobileMenuButton() {

  const toggleMenu = () => {
    $("body").toggleClass("enlarged");
  }

  return (
    <button className="button-menu-mobile open-left waves-effect" onClick={() => toggleMenu()}>
      <i className="mdi mdi-menu"></i>
    </button>
  )
}
