import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import axios from 'axios'
import { updateDevice } from '../../features/DevicesSlice'
import Switch from "react-switch";

export default function EditDeviceModal(props) {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.devices)
  const { user, stations, chargers } = useSelector((state) => state)
  const [device, setDevice] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const saveDevice = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('device_id', device.id)
    formData.append('charger_id', device.charger_id)
    formData.append('type', device.type)
    formData.append('uuid', device.uuid)
    formData.append('status', device.status)
    formData.append('mid', device.mid)
    formData.append('tid', device.tid)
    formData.append('autostart', device.autostart)
    formData.append('download_emv_params', device.download_emv_params)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/device/update', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(updateDevice(response.data.data))
      props.toggleShowEditModal()

    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {
    /* Get device from state */
    let device = devices.find(obj => {
      return obj.id == props.editId
    })

    setDevice(device)
  }, [props.editId])

  return (
    <Modal show={props.showEditModal} onHide={props.toggleShowEditModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          if (device) {
            return (
              <Form id="edit_device_form" onSubmit={saveDevice}>
                <Tabs defaultActiveKey="details" className="mb-3">
                  <Tab eventKey="details" title="Details">
                    <div className="row">
                      <div className="col-12">
                        <div className="card m-b-30">
                          <div className="card-body">
                            <div className="form-group row">
                              <div className="col-sm-6">
                                <label>Charger</label>
                                <select className="form-control" value={device.charger_id} onChange={(e) => { setDevice({ ...device, charger_id: e.target.value }) }}>
                                  <option>Select a Charger</option>
                                  {
                                    chargers.chargers.map((charger, index) => (
                                      <option key={index} value={charger.id}>{charger.serial_no}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              <div className="col-sm-6">
                                <label>Device Type</label>
                                <select className="form-control" value={device.type} onChange={(e) => { setDevice({ ...device, type: e.target.value }) }}>
                                  <option value="PAX IM30">PAX IM30</option>
                                  <option value="AMP6500">AMP6500</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row m-t-30">
                              <div className="col-sm-6">
                                <label>UUID</label>
                                <input className="form-control" type="text" id="input-serial-no" value={device.uuid} onChange={(e) => { setDevice({ ...device, uuid: e.target.value }) }} />
                              </div>
                              <div className="col-sm-6">
                                <label style={{ visibility: device.type == "AMP6500" ? "hidden" : "" }} >MID</label>
                                <input style={{ visibility: device.type == "AMP6500" ? "hidden" : "" }} className="form-control" type="text" id="input-mid" value={device.mid} onChange={(e) => { setDevice({ ...device, mid: e.target.value }) }} />
                              </div>
                            </div>
                            <div className="form-group row m-t-30">
                              <div className="col-sm-6">
                                <label style={{ visibility: device.type == "AMP6500" ? "hidden" : "" }} >TID</label>
                                <input style={{ visibility: device.type == "AMP6500" ? "hidden" : "" }} className="form-control" type="text" id="input-tid" value={device.tid} onChange={(e) => { setDevice({ ...device, tid: e.target.value }) }} />
                              </div>
                              <div className="col-sm-6">
                                <label>Autostart</label>
                                <select className="form-control" value={device.autostart} onChange={(e) => { setDevice({ ...device, autostart: e.target.value }) }}>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                            </div>
                            {device.type == "AMP6500" ?
                              <span>* You must manage this device via AMP360. Please visit their website to learn more.</span>
                              :
                              /* Keeps modal height the same */
                              <span>&nbsp;</span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="status" title="Status">
                    <div className="row">
                      <div className="col-12">
                        <div className="card m-b-30">
                          <div className="card-body">
                            <div className="form-group row">
                              <div className="col-sm-6">
                                <label>Status</label>
                                <select className="form-control" value={device.status} onChange={(e) => { setDevice({ ...device, status: e.target.value }) }}>
                                  <option value="1">Enabled</option>
                                  <option value="0">Disabled</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="gatewaySettings" title="Gateway">
                    <div className="form-group mb-0 row" style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                      <label style={{ paddingRight: "2rem", height: "2rem", lineHeight: "2rem", textAlign: "center" }}>Update Gateway Settings</label>
                      <Switch
                        onChange={() => { setDevice({ ...device, download_emv_params: device.download_emv_params == 1 ? 0 : 1 }) }}
                        checked={device.download_emv_params == 1 ? true : false }
                        onColor="#94CC3C"
                      />
                    </div>
                  </Tab>
                </Tabs>
              </Form>
            )
          } else {
            return (
              <></>
            )
          }
        })()}

      </Modal.Body>
      <Modal.Footer>
        <button disabled={disableSubmit} type="submit" form="edit_device_form" className="btn btn-success waves-effect waves-light">Save changes</button>
      </Modal.Footer>
    </Modal>
  )
}
