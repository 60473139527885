import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getCampaigns = createAsyncThunk('campaigns/getCampaigns', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/campaigns/all', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const getInactiveCampaigns = createAsyncThunk('campaigns/getInactiveCampaigns', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/campaigns/inactive', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: {
    campaigns: [],
    loading: '',
    inactiveCampaigns: []
  },
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action.payload
    },
    addCampaign: (state, action) => {
      state.campaigns = [...state.campaigns, action.payload]
    },
    updateCampaign: (state, action) => {
      /* Replace campaign with updated one */
      let stateCopy = [...state.campaigns];
      let index = stateCopy.findIndex((obj => obj.id == action.payload.id));
      stateCopy[index] = action.payload

      state.campaigns = stateCopy
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaigns.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload
      state.loading = ''
    })
    builder.addCase(getCampaigns.rejected, (state, action) => {
      state.loading = ''
    })
    builder.addCase(getInactiveCampaigns.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getInactiveCampaigns.fulfilled, (state, action) => {
      state.inactiveCampaigns = action.payload
      state.loading = ''
    })
    builder.addCase(getInactiveCampaigns.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setCampaigns, addCampaign, updateCampaign } = campaignsSlice.actions

export default campaignsSlice.reducer