import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getChargers = createAsyncThunk('chargers/getChargers', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/chargers/all', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const chargersSlice = createSlice({
  name: 'chargers',
  initialState: {
    chargers: [],
    loading: ''
  },
  reducers: {
    setChargers: (state, action) => {
      state.chargers = action.payload
    },
    updateCharger: (state, action) => {
      /* Replace charger with updated one */
      let stateCopy = [...state.chargers];
      let index = stateCopy.findIndex((obj => obj.id == action.payload.id));
      stateCopy[index] = action.payload

      state.chargers = stateCopy
    },
    deleteCharger: (state, action) => {
      let stateCopy = [...state.chargers];
      let index = stateCopy.findIndex((obj => obj.id == action.payload));

      if (index > -1) {
        stateCopy.splice(index, 1);
      }

      state.chargers = stateCopy
    },
    clearChargers: (state) => {
      state.chargers = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChargers.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getChargers.fulfilled, (state, action) => {
      state.chargers = action.payload
      state.loading = ''
    })
    builder.addCase(getChargers.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setChargers, updateCharger, deleteCharger, clearChargers } = chargersSlice.actions

export default chargersSlice.reducer