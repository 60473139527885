import React from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setToken } from '../../features/UserSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function LogoutButton(props) {
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  const navigate = useNavigate()

  async function logout() {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/logout`, {}, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });

      if (response.data.message === "Logout successful") {
        localStorage.removeItem('ctc_token');
        dispatch(setToken(null))
        navigate('/login')
      } else {
        alert(response.data.message)
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <a className="dropdown-item text-danger" onClick={() => logout()}><i className="mdi mdi-power text-danger"></i> Logout</a>
  )
}
