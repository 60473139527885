/* global Morris */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect, useReducer } from "react";
import useOnScreen from "../hooks/useOnScreen";
import $ from "jquery";
import { useSelector } from "react-redux";
import Helpers from "../../assets/js/Helpers";
import Oval from "react-loading-icons/dist/esm/components/oval";
const dayjs = require("dayjs");

export default function Charts(props) {
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const ref = useRef(null);
	const isVisible = useOnScreen(ref);
	const [scriptsLoaded, setScriptsLoaded] = useState(false);
	const chargeSessions = useSelector(
		(state) => state.chargeSessions.sessions
	);
	const [chartsInitialized, setChartsInitialized] = useState(false);
	const [keyIncrementer, setkeyIncrementer] = useState(0);
	const [range, setRange] = useState(props.range.substring(5));

	useEffect(() => {
		if (scriptsLoaded === false) {
			if (window.raphael === undefined) {
				const raphaelScript = document.createElement("script");
				raphaelScript.src = "assets/plugins/raphael/raphael.min.js";
				document.body.appendChild(raphaelScript);

				forceUpdate();
				return;
			}

			if (window.raphael == 1 && window.morris === undefined) {
				const morrisScript = document.createElement("script");
				morrisScript.src = "assets/plugins/morris/morris.min.js";
				document.body.appendChild(morrisScript);

				forceUpdate();
				return;
			}

			setScriptsLoaded(true);
		}
	});

	useEffect(() => {
		// Initialize Charts
		if (!chartsInitialized && scriptsLoaded) {
			drawCharts();
			setChartsInitialized(true);
		} else if (scriptsLoaded) {
			// Update charts
			drawCharts();
		}
	}, [keyIncrementer, props.chargeSessionsInRange]);

	const drawCharts = () => {
		if (props.range == "THIS WEEK") {
			// Create area chart
			let data = getDataForWeeks();

			let areaChart = Morris.Area({
				element: "morris-area-example",
				data: data,
				xkey: "y",
				ykeys: ["a", "b", "c"],
				yLabelFormat: function (y) {
					return (y = "$" + y.toFixed(2));
				},
				labels: ["This Week", "Last Week", "2 Weeks Ago"],
				lineColors: ["#fcbe2d", "#02c58d", "#30419b"],
				pointSize: 0,
				lineWidth: 0,
				resize: true,
				gridLineColor: "#eef0f2",
				hideHover: "auto",
				fillOpacity: 0.9,
				behaveLikeLine: true,
				parseTime: false,
			});

			createLegend(areaChart);

			// Create donut chart
			let thisWeeksTotal = 0;
			let lastWeeksTotal = 0;
			let twoWeeksAgoTotal = 0;

			data.forEach((d) => {
				thisWeeksTotal += d.a;
				lastWeeksTotal += d.b;
				twoWeeksAgoTotal += d.c;
			});

			let donutData = [
				{ label: "This week", value: thisWeeksTotal },
				{ label: "Last week", value: lastWeeksTotal },
				{ label: "2 weeks ago", value: twoWeeksAgoTotal },
			];

			let donutChart = Morris.Donut({
				element: "morris-donut-example",
				data: donutData,
				resize: true,
				colors: ["#fcbe2d", "#02c58d", "#30419b"],
				formatter: function (y, data) {
					return "$" + y.toFixed(2);
				},
			});

			donutChart.select(0);
		} else if (props.range == "THIS MONTH") {
			let data = getDataForMonths();

			let areaChart = Morris.Area({
				element: "morris-area-example",
				data: data,
				xkey: "y",
				ykeys: ["a", "b", "c"],
				yLabelFormat: function (y) {
					return (y = "$" + y.toFixed(2));
				},
				labels: ["This month", "Last month", "2 months ago"],
				lineColors: ["#fcbe2d", "#02c58d", "#30419b"],
				pointSize: 0,
				lineWidth: 0,
				resize: true,
				gridLineColor: "#eef0f2",
				hideHover: "auto",
				fillOpacity: 0.9,
				behaveLikeLine: true,
				parseTime: false,
				xLabelAngle: 45,
			});

			createLegend(areaChart);

			// Create donut chart
			let thisMonthsTotal = 0;
			let lastMonthsTotal = 0;
			let twoMonthsAgoTotal = 0;

			data.forEach((d) => {
				thisMonthsTotal += d.a;
				lastMonthsTotal += d.b;
				twoMonthsAgoTotal += d.c;
			});

			let donutData = [
				{ label: "This month", value: thisMonthsTotal },
				{ label: "Last month", value: lastMonthsTotal },
				{ label: "2 months ago", value: twoMonthsAgoTotal },
			];

			let donutChart = Morris.Donut({
				element: "morris-donut-example",
				data: donutData,
				resize: true,
				colors: ["#fcbe2d", "#02c58d", "#30419b"],
				formatter: function (y, data) {
					return "$" + y.toFixed(2);
				},
			});

			donutChart.select(0);
		} else if (props.range == "THIS YEAR") {
			let data = getDataForYears();

			let areaChart = Morris.Area({
				element: "morris-area-example",
				data: data,
				xkey: "y",
				ykeys: ["a", "b"],
				yLabelFormat: function (y) {
					return (y = "$" + y.toFixed(2));
				},
				labels: ["This year", "Last year"],
				lineColors: ["#fcbe2d", "#02c58d"],
				pointSize: 0,
				lineWidth: 0,
				resize: true,
				gridLineColor: "#eef0f2",
				hideHover: "auto",
				fillOpacity: 0.9,
				behaveLikeLine: true,
				parseTime: false,
				xLabelAngle: 45,
			});

			let thisYearsTotal = 0;
			let lastYearsTotal = 0;

			data.forEach((d) => {
				thisYearsTotal += d.a;
				lastYearsTotal += d.b;
			});

			let donutData = [
				{ label: "This year", value: thisYearsTotal },
				{ label: "Last year", value: lastYearsTotal },
			];

			let donutChart = Morris.Donut({
				element: "morris-donut-example",
				data: donutData,
				resize: true,
				colors: ["#fcbe2d", "#02c58d"],
				formatter: function (y, data) {
					return "$" + y.toFixed(2);
				},
			});

			donutChart.select(0);

			createLegend(areaChart);
		}
	};

	const createLegend = (areaChart) => {
		areaChart.options.data.forEach(function (label, i) {
			var legendItem = $("<span></span>")
				.text(areaChart.options.labels[i])
				.prepend("<i>&nbsp;</i>");
			legendItem
				.find("i")
				.css("backgroundColor", areaChart.options.lineColors[i]);
			$("#legend").append(legendItem);
		});
	};
	const getDataForWeeks = () => {
		let thisWeek = [
			dayjs().day(0),
			dayjs().day(1),
			dayjs().day(2),
			dayjs().day(3),
			dayjs().day(4),
			dayjs().day(5),
			dayjs().day(6),
		];

		let lastWeek = [
			dayjs().day(0).subtract(1, "week"),
			dayjs().day(1).subtract(1, "week"),
			dayjs().day(2).subtract(1, "week"),
			dayjs().day(3).subtract(1, "week"),
			dayjs().day(4).subtract(1, "week"),
			dayjs().day(5).subtract(1, "week"),
			dayjs().day(6).subtract(1, "week"),
		];

		let twoWeeksAgo = [
			dayjs().day(0).subtract(2, "week"),
			dayjs().day(1).subtract(2, "week"),
			dayjs().day(2).subtract(2, "week"),
			dayjs().day(3).subtract(2, "week"),
			dayjs().day(4).subtract(2, "week"),
			dayjs().day(5).subtract(2, "week"),
			dayjs().day(6).subtract(2, "week"),
		];

		let thisWeekTotals = [0, 0, 0, 0, 0, 0, 0];
		let lastWeekTotals = [0, 0, 0, 0, 0, 0, 0];
		let twoWeeksAgoTotals = [0, 0, 0, 0, 0, 0, 0];

		chargeSessions.forEach((cs) => {
			// This weeks totals
			thisWeek.forEach((weekDay, index) => {
				if (dayjs(cs.start_time).isSame(weekDay, "date")) {
					thisWeekTotals[index] += parseFloat(cs.total);
				}
			});

			// Last weeks totals
			lastWeek.forEach((asdf, index) => {
				if (dayjs(cs.start_time).isSame(asdf, "date")) {
					lastWeekTotals[index] += parseFloat(cs.total);
				}
			});

			// Two weeks ago totals
			twoWeeksAgo.forEach((asdf, index) => {
				if (dayjs(cs.start_time).isSame(asdf, "date")) {
					twoWeeksAgoTotals[index] += parseFloat(cs.total);
				}
			});
		});

		let data = [
			{
				y: "Sun",
				a: parseFloat(thisWeekTotals[0]),
				b: lastWeekTotals[0],
				c: twoWeeksAgoTotals[0],
			},
			{
				y: "Mon",
				a: thisWeekTotals[1],
				b: lastWeekTotals[1],
				c: twoWeeksAgoTotals[1],
			},
			{
				y: "Tue",
				a: thisWeekTotals[2],
				b: lastWeekTotals[2],
				c: twoWeeksAgoTotals[2],
			},
			{
				y: "Wed",
				a: thisWeekTotals[3],
				b: lastWeekTotals[3],
				c: twoWeeksAgoTotals[3],
			},
			{
				y: "Thu",
				a: thisWeekTotals[4],
				b: lastWeekTotals[4],
				c: twoWeeksAgoTotals[4],
			},
			{
				y: "Fri",
				a: thisWeekTotals[5],
				b: lastWeekTotals[5],
				c: twoWeeksAgoTotals[5],
			},
			{
				y: "Sat",
				a: thisWeekTotals[6],
				b: lastWeekTotals[6],
				c: twoWeeksAgoTotals[6],
			},
		];

		return data;
	};

	const getDataForMonths = () => {
		let day = 1;
		let data = [];

		while (day < 31) {
			let dayTotalThisMonth = 0;
			let dayTotalLastMonth = 0;
			let dayTotal2MonthsAgo = 0;

			chargeSessions.forEach((cs) => {
				// Get totals for each day of this month
				if (dayjs(cs.start_time).isSame(dayjs().date(day), "date")) {
					dayTotalThisMonth += parseFloat(cs.total);
				}

				// Get totals for each day of last month
				if (
					dayjs(cs.start_time).isSame(
						dayjs().date(day).subtract(1, "month"),
						"date"
					)
				) {
					dayTotalLastMonth += parseFloat(cs.total);
				}

				// Get totals for each day of 2 month ago
				if (
					dayjs(cs.start_time).isSame(
						dayjs().date(day).subtract(2, "month"),
						"date"
					)
				) {
					dayTotal2MonthsAgo += parseFloat(cs.total);
				}
			});

			data.push({
				y: Helpers.getOrdinal(parseInt(day)),
				a: dayTotalThisMonth,
				b: dayTotalLastMonth,
				c: dayTotal2MonthsAgo,
			});

			day++;
		}

		return data;
	};

	const getDataForYears = () => {
		let months = 0;
		let data = [];
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		while (months < 12) {
			let thisYearMonthTotal = 0;
			let lastYearMonthTotal = 0;

			chargeSessions.forEach((cs) => {
				let sessionStartTime = dayjs(cs.start_time);

				// Get YYYY-MM-DD of first and last day of last 2 years
				let firstDayOfThisYear = new Date(
					new Date().getFullYear(),
					0,
					1
				)
					.toISOString()
					.split("T")[0];
				let lastDayOfThisYear = new Date(
					new Date().getFullYear(),
					11,
					31
				);

				let firstDayOfLastYear = new Date(
					new Date().getFullYear(),
					0,
					1
				);
				let lastDayOfLastYear = new Date(
					new Date().getFullYear(),
					11,
					31
				);
				firstDayOfLastYear.setFullYear(
					firstDayOfLastYear.getFullYear() - 1
				);
				lastDayOfLastYear.setFullYear(
					lastDayOfLastYear.getFullYear() - 1
				);

				firstDayOfLastYear = firstDayOfLastYear
					.toISOString()
					.split("T")[0];
				lastDayOfThisYear = lastDayOfThisYear
					.toISOString()
					.split("T")[0];
				lastDayOfLastYear = lastDayOfLastYear
					.toISOString()
					.split("T")[0];

				// Get this years month totals
				if (
					sessionStartTime.isAfter(firstDayOfThisYear) &&
					sessionStartTime.isBefore(lastDayOfThisYear) &&
					sessionStartTime.isSame(dayjs().month(months), "month")
				) {
					thisYearMonthTotal += parseFloat(cs.total);
				}

				// Get last years month totals
				if (
					sessionStartTime.isAfter(firstDayOfLastYear) &&
					sessionStartTime.isBefore(lastDayOfLastYear) &&
					sessionStartTime.isSame(
						dayjs().month(months).subtract(1, "year"),
						"month"
					)
				) {
					lastYearMonthTotal += parseFloat(cs.total);
				}
			});

			data.push({
				y: monthNames[months],
				a: thisYearMonthTotal,
				b: lastYearMonthTotal,
			});

			months++;
		}

		return data;
	};

	useEffect(() => {
		// Increment key to completely remove graphs from DOM
		setkeyIncrementer(keyIncrementer + 1);
	}, [props.range, props.chargeSessionsInRange]);

	// if (chartsInitialized)
	return (
		<div key={keyIncrementer} className="row">
			<div className="col-xl-8">
				<div className="card m-b-30">
					<div className="card-body">
						<h4 className="mt-0 header-title mb-4">
							Sales by {props.range.substring(5)}
						</h4>

						{!chartsInitialized && (
							<div
								style={{ textAlign: "center", height: "25rem" }}
							>
								<Oval
									stroke="#30419b"
									width="5rem"
									height="5rem"
									style={{ marginTop: "8rem" }}
								/>
							</div>
						)}
						<div
							ref={ref}
							id="morris-area-example"
							className="morris-charts morris-chart-height"
						></div>
						<div
							style={{ marginTop: "25px" }}
							id="legend"
							className="area-legend"
						></div>

						{/*<div style={{ height: "35rem", width: "100%", backgroundColor: "yellow", position: "absolute", top: 0, left: 0 }}></div>*/}
					</div>
				</div>
			</div>

			<div className="col-xl-4">
				<div className="card m-b-30">
					<div className="card-body">
						<h4 className="mt-0 header-title mb-4">
							Sales by {props.range.substring(5)}
						</h4>
						{!chartsInitialized && (
							<div
								style={{ textAlign: "center", height: "25rem" }}
							>
								<Oval
									stroke="#30419b"
									width="5rem"
									height="5rem"
									style={{ marginTop: "8rem" }}
								/>
							</div>
						)}
						<div
							id="morris-donut-example"
							className="morris-charts morris-chart-height"
						></div>
					</div>
				</div>
			</div>
		</div>
	);
}
