import React, { useEffect, useState } from "react";
import TopBar from "../sections/TopBar";
import Menu from "../sections/Menu";
import { useDispatch, useSelector } from "react-redux";
import { getMedia } from "../../features/MediaSlice";
import {
	Row,
	Col,
	Image,
	Form,
	Card,
	Button,
	CloseButton,
	InputGroup,
	FloatingLabel,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Oval } from "react-loading-icons";

let loopArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const imgStyle = {
	maxWidth: "140px",
	padding: "7px",
	display: "block",
	margin: "auto",
};

const colStyle = {
	padding: 0,
	textAlign: "center",
	marginBottom: "25px",
};

const badgeStyle = {
	width: "15px",
	height: "15px",
	backgroundColor: "yellow",
	position: "absolute",
	top: 0,
	right: 0,
};

export default function Media() {
	const dispatch = useDispatch();
	const media = useSelector((state) => state.media);
	const { user } = useSelector((state) => state);
	const [imageUpload, setImageUpload] = useState(null);
	const [mediaTitle, setMediaTitle] = useState("");
	const [mediaDescription, setMediaDescription] = useState("");
	const [mediaTitles, setMediaTitles] = useState({});
	const [mediaDescriptions, setMediaDescriptions] = useState({});

	const deleteMedia = async (id) => {
		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("media_id", id);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/media/delete",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			dispatch(getMedia());
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	const saveMediaText = async (mediaId) => {
		//setDisableSubmit(true)

		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("media_id", mediaId);
		formData.append("title", mediaTitles[mediaId]);
		formData.append("description", mediaDescriptions[mediaId]);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/media/description/update",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		/*setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)*/

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			//dispatch(getDevices())
			//props.toggleShowAddModal()
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	const submitImageUpload = async (e) => {
		e.preventDefault();

		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("image", imageUpload);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/media/store",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			document.getElementById("myFile").value = "";
			dispatch(getMedia());
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	useEffect(() => {
		if (!media.data.length > 0) {
			dispatch(getMedia());
		}
	}, []);

	useEffect(() => {
		// Set title and descriptions for media
		if (media.data.length > 0) {
			let mTitles = {};
			let mDescriptions = {};

			media.data.forEach((m) => {
				let mediaId = m.id;

				mTitles[mediaId] = m.title;
				mDescriptions[mediaId] = m.description;
			});

			setMediaTitles(mTitles);
			setMediaDescriptions(mDescriptions);
		}
	}, [media]);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Media
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Advertising</a>
											</li>
											<li className="breadcrumb-item active">
												Media
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a listing of all media
												per account. You can add or
												delete media at any time.
											</p>
											<br />
											<p
												style={{
													display: "inline-block",
												}}
												className="sub-title"
											>
												Need to upload new media?
											</p>
											<Form
												style={{
													display: "inline-block",
													marginLeft: "1rem",
													marginBottom: "3rem",
												}}
												id="upload_image_form"
												onSubmit={submitImageUpload}
											>
												<input
													type="file"
													id="myFile"
													name="filename"
													onChange={(e) =>
														setImageUpload(
															e.target.files[0]
														)
													}
												/>
												<button
													type="submit"
													form="upload_image_form"
													className="btn btn-success waves-effect waves-light"
												>
													Upload
												</button>
											</Form>
											<br></br>
											{
												media.data.length > 0 &&
													media.data.map((m) => {
														return (
															<Card
																style={{
																	width: "15rem",
																	display:
																		"inline-block",
																	textAlign:
																		"center",
																}}
															>
																{m.type ===
																"mp4" ? (
																	<video
																		src={
																			"data:video/mp4;base64," +
																			m.base64
																		}
																		width="250px"
																		height="100px"
																	></video>
																) : (
																	<Card.Img
																		variant="top"
																		style={{
																			maxWidth:
																				"140px",
																			padding:
																				"7px",
																		}}
																		src={
																			"data:image/png;base64," +
																			m.base64
																		}
																	/>
																)}
																<Card.Body
																	style={{
																		textAlign:
																			"center",
																	}}
																>
																	<Form.Label htmlFor="title">
																		Title
																	</Form.Label>
																	<Form.Control
																		className="m-b-30"
																		type="text"
																		id="title"
																		value={
																			mediaTitles[
																				m
																					.id
																			]
																		}
																		onChange={(
																			e
																		) =>
																			setMediaTitles(
																				{
																					...mediaTitles,
																					[m.id]:
																						e
																							.target
																							.value,
																				}
																			)
																		}
																	/>

																	<Form.Label htmlFor="description">
																		Description
																	</Form.Label>
																	<Form.Control
																		className="m-b-30"
																		type="text"
																		id="description"
																		value={
																			mediaDescriptions[
																				m
																					.id
																			]
																		}
																		onChange={(
																			e
																		) =>
																			setMediaDescriptions(
																				{
																					...mediaDescriptions,
																					[m.id]:
																						e
																							.target
																							.value,
																				}
																			)
																		}
																	/>

																	<Button
																		style={{
																			width: "5rem",
																		}}
																		variant="danger"
																		onClick={() =>
																			deleteMedia(
																				m.id
																			)
																		}
																	>
																		Delete
																	</Button>
																	<Button
																		style={{
																			marginLeft:
																				"1rem",
																			width: "5rem",
																		}}
																		variant="success"
																		onClick={() =>
																			saveMediaText(
																				m.id
																			)
																		}
																	>
																		Save
																	</Button>
																</Card.Body>
															</Card>
														);
													})
												//  : <div style={{ textAlign: "center", height: "25rem" }}><Oval stroke="#30419b" width="5rem" height="5rem" style={{ marginTop: "8rem" }} /></div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
