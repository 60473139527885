/* eslint-disable */
import React, { useState, useEffect } from "react";
import Menu from "../sections/Menu";
import MobileMenuButton from "../buttons/MobileMenuButton";
import NavbarRight from "../sections/NavbarRight";
import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-bs4";
import useScriptImporter from "../hooks/useScriptImporter";
import { useSelector, useDispatch } from "react-redux";
import AddDeviceModal from "../modals/AddDeviceModal";
import { clearDevices, getDevices } from "../../features/DevicesSlice";
import ActiveBadge from "../sections/StatusTD";
import StatusTD from "../sections/StatusTD";
import EditDeviceModal from "../modals/EditDeviceModal";
import DeleteModal from "../modals/DeleteModal";
import TopBar from "../sections/TopBar";
import { chargersSlice } from "../../features/ChargersSlice";
import Helpers from "../../assets/js/Helpers";
import { getCustomers } from "../../features/CustomersSlice";
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

DataTable.Buttons.pdfMake(pdfMake);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Devices() {
	const scriptsImported = useScriptImporter();
	const devices = useSelector((state) => state.devices.devices);
	const [deviceList, setDeviceList] = useState([]);
	const customers = useSelector((state) => state.customers.customers);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [showAddModal, setShowAddModal] = useState(false);
	const [keyIncrementer, setKeyIncrementer] = useState(0);
	const [editId, setEditId] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState(false);
	const targetColumns = user.data.role == "SUPER ADMIN" ? [-1, -2] : [-1];

	const toggleShowAddModal = () => {
		setShowAddModal(!showAddModal);
	};

	const toggleShowEditModal = () => {
		setShowEditModal(!showEditModal);
	};

	const toggleShowDeleteModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	useEffect(() => {
		dispatch(getDevices());

		if (customers.length === 0) {
			dispatch(getCustomers());
		}
		return () => {
			dispatch(clearDevices());
		};
	}, []);

	useEffect(() => {
		$("#datatable").DataTable({
			pageLength: 10,
			lengthChange: true,
			lengthMenu: [10, 20, 50, 100, 200, 500],
			dom: "Blfrtip",
			columnDefs: [
				{
					targets: targetColumns,
					className: "all",
				},
			],
			buttons: [
				{ extend: "copyHtml5", filename: "devices", title: "Devices" },
				{ extend: "excelHtml5", filename: "devices", title: "Devices" },
				{ extend: "csvHtml5", filename: "devices", title: "Devices" },
				{
					extend: "pdfHtml5",
					filename: "devices",
					title: "Devices",
					orientation: "landscape",
				},
			],
		});
	}, [keyIncrementer]);

	useEffect(() => {
		/**
		 * Using a key and an incrementing it when this state
		 * changes will cause the element to be removed and
		 * re-rendered, this way we can refresh the datatable correctly
		 */
		setKeyIncrementer(keyIncrementer + 1);
		setDeviceList(devices);
	}, [devices]);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Devices
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Devices</a>
											</li>
											<li className="breadcrumb-item active">
												Listing
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a listing of all devices
												per account. You can filter on
												the station column to list all
												devices within a station.
												<span
													style={{ float: "right" }}
												>
													<a
														className="btn btn-success btn-md"
														style={{
															color: "white",
														}}
														onClick={() =>
															toggleShowAddModal()
														}
													>
														<i className="fa fa-plus"></i>{" "}
														Add
													</a>
												</span>
											</p>
											<div key={keyIncrementer}>
												<table
													id="datatable"
													className="table table-bordered dt-responsive nowrap"
													style={{
														borderCollapse:
															"collapse",
														borderSpacing: 0,
														width: "100%",
													}}
												>
													<thead>
														<tr>
															{user.data.role ==
															"SUPER ADMIN" ? (
																<th>
																	Customer
																</th>
															) : (
																<></>
															)}
															<th>UUID</th>
															<th>Charger</th>
															<th>Station</th>
															<th>Merchant ID</th>
															<th>Terminal ID</th>
															<th>Type</th>
															<th>Status</th>
															<th>
																Last Check In
															</th>
															<th>Edit</th>
															{user.data.role ==
															"SUPER ADMIN" ? (
																<th>Delete</th>
															) : (
																<></>
															)}
														</tr>
													</thead>

													<tbody>
														{deviceList.map(
															(device, index) => {
																return (
																	<tr
																		key={
																			index
																		}
																		style={{
																			background:
																				device.status ==
																				0
																					? "lightpink"
																					: "",
																		}}
																	>
																		{user
																			.data
																			.role ==
																		"SUPER ADMIN" ? (
																			<td>
																				{Helpers.getCustomerName(
																					device.merchant_id,
																					customers
																				)}
																			</td>
																		) : (
																			<>

																			</>
																		)}
																		<td>
																			{
																				device.uuid
																			}
																		</td>
																		<td>
																			{
																				device.charger_serial_no
																			}
																		</td>
																		<td>
																			{
																				device.station_name
																			}
																		</td>
																		<td>
																			{
																				device.mid
																			}
																		</td>
																		<td>
																			{
																				device.tid
																			}
																		</td>
																		<td>
																			{
																				device.type
																			}
																		</td>
																		<StatusTD
																			status={
																				device.status
																			}
																		/>
																		<td>
																			{dayjs
																				.utc(
																					device.updated_at
																				)
																				.tz(
																					Intl.DateTimeFormat().resolvedOptions()
																						.timeZone
																				)
																				.format(
																					"MM/DD/YYYY h:mm a"
																				)}
																		</td>
																		<td
																			style={{
																				textAlign:
																					"center",
																			}}
																		>
																			<button
																				type="button"
																				className="btn btn-primary"
																				onClick={() => {
																					toggleShowEditModal();
																					setEditId(
																						device.id
																					);
																				}}
																			>
																				<i className="mdi mdi-pencil text-white"></i>
																			</button>
																		</td>
																		{user
																			.data
																			.role ==
																		"SUPER ADMIN" ? (
																			<td
																				style={{
																					textAlign:
																						"center",
																				}}
																			>
																				<button
																					type="button"
																					className="btn btn-danger"
																					onClick={() => {
																						toggleShowDeleteModal();
																						setDeleteId(
																							device.id
																						);
																					}}
																				>
																					<i className="mdi mdi-trash-can-outline text-white"></i>
																				</button>
																			</td>
																		) : (
																			<>

																			</>
																		)}
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<AddDeviceModal
						toggleShowAddModal={toggleShowAddModal}
						showAddModal={showAddModal}
					/>
					<EditDeviceModal
						toggleShowEditModal={toggleShowEditModal}
						showEditModal={showEditModal}
						editId={editId}
					/>
					<DeleteModal
						toggleShowDeleteModal={toggleShowDeleteModal}
						showDeleteModal={showDeleteModal}
						deleteId={deleteId}
						resourceType={"DEVICE"}
					/>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
