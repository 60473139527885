import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setToken } from '../../features/UserSlice'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function Login(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [error, setError] = useState("")

  async function loginUser(e) {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/login`, {
        email: email,
        password: password
      });

      if (response.data.token) {
        localStorage.setItem('ctc_token', response.data.token)
        dispatch(setToken(localStorage.getItem('ctc_token')))
        setError("")
        navigate('/')
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="accountbg">
      <div className="wrapper-page">
        <div className="card card-pages shadow-none">

          <div className="card-body">
            <div className="text-center m-t-0 m-b-15">
              <a href="pages-login.html" className="logo logo-admin"><img src="assets/images/logo-color-tm.jpg" alt="" height="24" /></a>
            </div>
            <h5 className="font-18 text-center">Sign in to continue</h5>

            <form className="form-horizontal m-t-30" onSubmit={(e) => loginUser(e)}>

              {error !== "" && <div className='text-center error'>{error}</div>}
              <div className="form-group">
                <div className="col-12">
                  <label>Email</label>
                  <input className="form-control" type="text" required placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>

              <div className="form-group">
                <div className="col-12">
                  <label>Password</label>
                  <input className="form-control" type="password" required placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
              </div>

              <div className="form-group">
                <div className="col-12">
                  <div className="checkbox checkbox-primary">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label" for="customCheck1"> Remember me</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group text-center m-t-20">
                <div className="col-12">
                  <button className="btn btn-success btn-block btn-lg waves-effect waves-light" type="submit">Log In</button>
                </div>
              </div>

              <div className="form-group row m-t-30 m-b-0 text-center">
                <div className="col-12">
                  <Link to="/forgot-password" className="text-muted"><i className="fa fa-lock m-r-5"></i> Forgot your password?</Link>
                </div>
                {/*<div className="col-sm-5 text-right">
                  <Link to="/register" className="text-muted">Create an account</Link>
  </div>*/}
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  )
}
