import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getStations = createAsyncThunk('stations/getStations', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/stations/all', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const stationsSlice = createSlice({
  name: 'stations',
  initialState: {
    stations: [],
    loading: ''
  },
  reducers: {
    setStations: (state, action) => {
      state.stations = action.payload
    },
    updateStation: (state, action) => {
      /* Replace station with updated one */
      let stateCopy = [...state.stations];
      let index = stateCopy.findIndex((obj => obj.id == action.payload.id));
      stateCopy[index] = action.payload

      state.stations = stateCopy
    },
    deleteStation: (state, action) => {
      let stateCopy = [...state.stations];
      let index = stateCopy.findIndex((obj => obj.id == action.payload));

      if (index > -1) {
        stateCopy.splice(index, 1);
      }

      state.stations = stateCopy
    },
    clearStations: (state) => {
      state.stations = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStations.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getStations.fulfilled, (state, action) => {
      state.stations = action.payload
      state.loading = ''
    })
    builder.addCase(getStations.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setStations, updateStation, deleteStation, clearStations } = stationsSlice.actions

export default stationsSlice.reducer