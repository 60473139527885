import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setToken } from '../../features/UserSlice'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('')

  async function sendResetLink(e) {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/password/reset`, {
        email: email,
      });

      if (response.data.code === 0) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setEmail('')
      } else {
        for (const [key, value] of Object.entries(response.data.errors)) {
          toast.error(`${value}`, {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="accountbg">
      <div className="wrapper-page">
        <div className="card card-pages shadow-none">

          <div className="card-body">
            <div className="text-center m-t-0 m-b-15">
              <a href="pages-login.html" className="logo logo-admin"><img src="assets/images/logo-color-tm.jpg" alt="" height="24" /></a>
            </div>
            <h5 className="font-18 text-center">Reset your password</h5>

            <form className="form-horizontal" style={{ marginTop: "5rem" }} onSubmit={(e) => sendResetLink(e)}>

              <div className="form-group">
                <div className="col-12">
                  <label>Email</label>
                  <input className="form-control" type="text" required="" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>

              <div className="form-group text-center" style={{ marginTop: "4rem" }}>
                <div className="col-12">
                  <button className="btn btn-success btn-block btn-lg waves-effect waves-light" type="submit">Send Email</button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  )
}
