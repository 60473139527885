import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import axios from 'axios'
import { deleteStation } from '../../features/StationsSlice'
import { deleteCharger } from '../../features/ChargersSlice'
import { deleteDevice } from '../../features/DevicesSlice'
import { deleteCustomer } from '../../features/CustomersSlice'
import { useNavigate } from 'react-router-dom'
import { getCampaigns } from '../../features/CampaignsSlice'

export default function DeleteModal(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const user = useSelector((state) => state.user)
  const campaignDeleteId = useSelector((state) => state.campaigns.deleteId)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const deleteResource = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    let deleteEndpoint = ''

    switch (props.resourceType) {
      case 'STATION':
        deleteEndpoint = '/api/station/delete'
        break
      case 'CHARGER':
        deleteEndpoint = '/api/charger/delete'
        break
      case 'DEVICE':
        deleteEndpoint = '/api/device/delete'
        break
      case 'CUSTOMER':
        deleteEndpoint = '/api/customer/delete'
        break
      case 'CAMPAIGN':
        deleteEndpoint = '/api/campaign/delete'
        break
    }

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('id', props.deleteId)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + deleteEndpoint, formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      switch (props.resourceType) {
        case 'STATION':
          /**
           * If there is more than 1 page on the datatable react throws a removeChild node error.
           * Here we check if there's more than 1 (previous and next buttons count as 2 of them)
           * and reload the page instead to prevent the error
           */
          if (document.querySelectorAll('.paginate_button').length > 3) {
            navigate(0)
          } else {
            dispatch(deleteStation(props.deleteId))
          }
          break;
        case 'CHARGER':
          if (document.querySelectorAll('.paginate_button').length > 3) {
            navigate(0)
          } else {
            dispatch(deleteCharger(props.deleteId))
          }
          break;
        case 'DEVICE':
          if (document.querySelectorAll('.paginate_button').length > 3) {
            navigate(0)
          } else {
            dispatch(deleteDevice(props.deleteId))
          }
          break;
        case 'CUSTOMER':
          if (document.querySelectorAll('.paginate_button').length > 3) {
            navigate(0)
          } else {
            dispatch(deleteCustomer(props.deleteId))
          }
          break;
        case 'CAMPAIGN':
          props.toggleCampaignEditModal()
          dispatch(getCampaigns())
          break;
      }

      props.toggleShowDeleteModal()

    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  return (
    <Modal show={props.showDeleteModal} onHide={props.toggleShowDeleteModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {props.resourceType.charAt(0).toUpperCase() + props.resourceType.slice(1).toLowerCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span style={{ fontSize: "16px", marginTop: "50px", marginBottom: "50px", display: "block" }}>Are you sure you want to delete this {props.resourceType.toLowerCase()}?</span>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowDeleteModal}>Cancel</button>
        <button type="button" className="btn btn-danger waves-effect waves-light" onClick={(e) => deleteResource(e)}>Delete</button>
      </Modal.Footer>
    </Modal>
  )
}
