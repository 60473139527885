import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setToken } from '../../features/UserSlice'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Switch from "react-switch";
import { toast } from 'react-toastify'

export default function Register() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState('');
  const [switchChecked, setSwitchChecked] = useState(false)
  const [isRegisterAsRole, setIsRegisterAsRole] = useState(false)
  const [role, setRole] = useState('')
  const [merchantId, setMerchantId] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)

  const handleSwitchChange = () => {
    setSwitchChecked(!switchChecked)
  }

  const registerUser = async (e) => {
    e.preventDefault()

    if (isRegisterAsRole) {
      let urlEmail = queryParameters.get('email')
      let urlRole = queryParameters.get('role')
      let urlMerchantId = queryParameters.get('merchant_id')

      if (!urlEmail || !urlRole || !urlMerchantId) {
        toast.error("Missing registration parameters", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return false;
      }
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/register`, {
        name: name,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
        api_user: switchChecked,
        role: role,
        merchant_id: merchantId
      });

      if (response.data.token) {
        localStorage.setItem('ctc_token', response.data.token)
        dispatch(setToken(localStorage.getItem('ctc_token')))

        navigate('/')
      } else {
        for (const [key, value] of Object.entries(response.data.errors)) {
          toast.error(`${value}`, {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      setErrors(error.response.data.errors)
    }
  }

  useEffect(() => {
    let urlEmail = queryParameters.get('email')
    let urlRole = queryParameters.get('role')
    let urlMerchantId = queryParameters.get('merchant_id')

    if (urlEmail && urlRole) {
      setIsRegisterAsRole(true)
      setEmail(urlEmail)
      setRole(urlRole)
      setMerchantId(urlMerchantId)
    }
  }, [])

  return (
    <div class="accountbg">
      <div class="wrapper-page">
        <div class="card card-pages shadow-none">

          <div class="card-body">
            <div class="text-center m-t-0 m-b-15">
              <a class="logo logo-admin"><img src="assets/images/logo-color-tm.jpg" alt="" height="24" /></a>
            </div>
            <h5 class="font-18 text-center">Register Your Account</h5>

            <form class="form-horizontal m-t-30" onSubmit={(e) => registerUser(e)}>

              {isRegisterAsRole ?
                <div class="form-group">
                  <div class="col-12">
                    {/* <label>Role</label> */}
                    <input class="form-control" type="text" required="" hidden disabled value={role} />
                  </div>
                </div>
                :
                <></>
              }
              <div class="form-group">
                <div class="col-12">
                  <label>Name</label>
                  <input class="form-control" type="text" required placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
              </div>

              <div class="form-group">
                <div class="col-12">
                  <label>Email</label>
                  <input class="form-control" type="text" required disabled={isRegisterAsRole ? true : false} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>

              <div class="form-group">
                <div class="col-12">
                  <label>Password</label>
                  <input class="form-control" type="password" required placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
              </div>

              <div class="form-group">
                <div class="col-12">
                  <label>Confirm Password</label>
                  <input class="form-control" type="password" required placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
              </div>

              <div class="col-12" style={{ marginBottom: "1.5rem" }}>
                {Object.entries(errors).map(([key, value]) => (
                  <span style={{ display: "inline-block", color: "red" }}>{value}</span>
                ))}
              </div>

              <div class="form-group">
                <div class="col-12">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" required />
                    <label class="custom-control-label font-weight-normal" for="customCheck1">I accept <a href="" class="text-primary">Terms and Conditions</a></label>
                  </div>
                </div>
              </div>

              <div class="form-group text-center m-t-20">
                <div class="col-12">
                  <button class="btn btn-success btn-block btn-lg waves-effect waves-light" type="submit">Register</button>
                </div>
              </div>

              <div class="form-group mb-0 row">
                <div class="col-12 m-t-10 text-center">
                  <Link to="/login" class="text-muted">Already have account?</Link>
                </div>

                <div class="col-12 m-t-10 text-center" style={{ height: "1rem" }}>
                </div>

                {isRegisterAsRole ?
                  <></>
                  :
                  <>
                    <span class="text-muted" style={{ marginLeft: "5rem", paddingRight: "2rem", height: "2rem", lineHeight: "2rem", textAlign: "center" }}>Registering as a developer?</span>
                    <Switch
                      onChange={handleSwitchChange}
                      checked={switchChecked}
                      onColor="#94CC3C"
                    />
                  </>
                }
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  )
}
