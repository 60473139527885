import React, { useEffect, useState } from "react";
import TopBar from "../sections/TopBar";
import Menu from "../sections/Menu";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button, Form, Modal, Tab, Tabs, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { getMedia } from "../../features/MediaSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { getStations } from "../../features/StationsSlice";
import { getCampaigns } from "../../features/CampaignsSlice";
import { format } from "date-fns";
import EditCampaignModal from "../modals/EditCampaignModal";
import Helpers from "../../assets/js/Helpers";

function renderEventContent(eventInfo) {
	return (
		<>
			<b>{eventInfo.timeText}</b>
			<i style={{ marginLeft: "1rem" }}>{eventInfo.event.title}</i>
		</>
	);
}

export default function Campaigns(props) {
	const dispatch = useDispatch();
	const { user, stations } = useSelector((state) => state);
	const media = useSelector((state) => state.media);
	const campaigns = useSelector((state) => state.campaigns.campaigns);
	const [show, setShow] = useState(false);
	const [name, setName] = useState("");
	const [campaignType, setCampaignType] = useState("");
	const [stationId, setStationId] = useState("");
	const [startDate, setStartDate] = useState(
		format(Date.now(), "yyyy-MM-dd")
	);
	const [startTime, setStartTime] = useState("");
	const [endDate, setEndDate] = useState();
	const [endTime, setEndTime] = useState("");
	const [timezone, setTimezone] = useState("");
	const [campaignId, setCampaignId] = useState("");
	const [allDayChecked, setAllDayChecked] = useState(false);
	const [sundayChecked, setSundayChecked] = useState(false);
	const [mondayChecked, setMondayChecked] = useState(false);
	const [tuesdayChecked, setTuesdayChecked] = useState(false);
	const [wednesdayChecked, setWednesdayChecked] = useState(false);
	const [thursdayChecked, setThursdayChecked] = useState(false);
	const [fridayChecked, setFridayChecked] = useState(false);
	const [saturdayChecked, setSaturdayChecked] = useState(false);
	const [recurring, setRecurring] = useState("EVERY_DAY");
	const [daySelect, setDaySelect] = useState("date");
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [calendarEvents, setCalendarEvents] = useState([]);
	const [dayDescription, setDayDescription] = useState("1st of each month");
	const [dateDescription, setDateDescription] = useState(
		"2nd Monday of each month"
	);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editId, setEditId] = useState(null);
	const [chargeScreenText1, setChargeScreenText1] = useState("");
	const [chargeScreenText2, setChargeScreenText2] = useState("");
	const [chargeScreenText3, setChargeScreenText3] = useState("");
	const [chargeScreenText4, setChargeScreenText4] = useState("");
	const [chargeScreenText5, setChargeScreenText5] = useState("");
	const [chargeScreenText6, setChargeScreenText6] = useState("");
	const [chargeScreenText7, setChargeScreenText7] = useState("");
	const [chargeScreenText8, setChargeScreenText8] = useState("");
	const [chargeScreenText9, setChargeScreenText9] = useState("");
	const [chargeScreenText10, setChargeScreenText10] = useState("");

	const handleClose = () => {
		setShow(false);
		setName("");
		setCampaignType("");
		setStationId("");
		setStartDate("");
		setStartTime("");
		setEndDate("");
		setEndTime("");
		setTimezone("");
		setCampaignId("");
		setAllDayChecked(false);
		setSundayChecked(false);
		setMondayChecked(false);
		setTuesdayChecked(false);
		setWednesdayChecked(false);
		setThursdayChecked(false);
		setFridayChecked(false);
		setSaturdayChecked(false);
		setRecurring("EVERY_DAY");
		setDaySelect("date");
		setDisableSubmit(false);
		setChargeScreenText1("");
		setChargeScreenText2("");
		setChargeScreenText3("");
		setChargeScreenText4("");
		setChargeScreenText5("");
		setChargeScreenText6("");
		setChargeScreenText7("");
		setChargeScreenText8("");
		setChargeScreenText9("");
		setChargeScreenText10("");
	};

	const handleShow = () => setShow(true);

	const toggleShowEditModal = () => {
		// If hiding modal, reset the editId
		if (showEditModal) {
			setEditId(null);
		}

		setShowEditModal(!showEditModal);
	};

	function editEvent(eventInfo) {
		let campaign_id = eventInfo.event._def.extendedProps.campaign_id;

		setEditId(campaign_id);
		toggleShowEditModal();
	}

	const createCampaign = async (e) => {
		e.preventDefault();
		setDisableSubmit(true);

		/* Get array of image id's for for campaign */
		var imageIds = [];
		var checkboxes = document.querySelectorAll(
			"input[class=image-select]:checked"
		);

		for (var i = 0; i < checkboxes.length; i++) {
			imageIds.push(checkboxes[i].value);
		}

		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("name", name);
		formData.append("type", campaignType);
		formData.append("station_id", stationId);
		formData.append("start_date", startDate);
		formData.append("start_time", startTime);
		formData.append("end_date", endDate);
		formData.append("end_time", endTime);
		formData.append("timezone", "CST");
		formData.append("all_day", allDayChecked);
		formData.append("sunday", sundayChecked);
		formData.append("monday", mondayChecked);
		formData.append("tuesday", tuesdayChecked);
		formData.append("wednesday", wednesdayChecked);
		formData.append("thursday", thursdayChecked);
		formData.append("friday", fridayChecked);
		formData.append("saturday", saturdayChecked);
		formData.append("recurring", recurring);
		formData.append("monthly_period", daySelect);
		formData.append("image_ids", imageIds);
		formData.append("charge_screen_text_1", chargeScreenText1);
		formData.append("charge_screen_text_2", chargeScreenText2);
		formData.append("charge_screen_text_3", chargeScreenText3);
		formData.append("charge_screen_text_4", chargeScreenText4);
		formData.append("charge_screen_text_5", chargeScreenText5);
		formData.append("charge_screen_text_6", chargeScreenText6);
		formData.append("charge_screen_text_7", chargeScreenText7);
		formData.append("charge_screen_text_8", chargeScreenText8);
		formData.append("charge_screen_text_9", chargeScreenText9);
		formData.append("charge_screen_text_10", chargeScreenText10);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/campaign/create",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
					},
				}
			)
			.catch((error) => {
				toast.error("Something went wrong", {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			});

		setTimeout(() => {
			setDisableSubmit(false);
		}, 3000);

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			handleClose();
			dispatch(getCampaigns());
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	/* Get data necessary for page */
	useEffect(() => {
		dispatch(getMedia());

		if (!campaigns.length > 0) {
			dispatch(getCampaigns());
		}

		if (!stations.stations.length > 0) {
			dispatch(getStations());
		}
	}, []);

	// Convert 2023-04-23 12:12:12 to 2023-04-23T12:12:12
	const convertDate = (date) => {
		return date.replace(/\s/g, "T");
	};

	useEffect(() => {
		let campaignArray = [];

		campaigns.forEach((campaign) => {
			campaign.campaign_times.forEach((campaign_time) => {
				let c = {
					campaign_id: campaign.id,
					title: campaign.name,
					start: new Date(convertDate(campaign_time.start_time)),
					end: new Date(convertDate(campaign_time.end_time)),
				};
				campaignArray.push(c);
			});
		});

		setCalendarEvents(campaignArray);
	}, [campaigns]);

	useEffect(() => {
		let d = new Date(startDate);
		let dayOfMonth = d.getUTCDate();
		setDayDescription(Helpers.getOrdinal(dayOfMonth) + " of each month");
	}, [startDate]);

	const handleStationChange = (e) => {
		let value = Array.from(
			e.target.selectedOptions,
			(option) => option.value
		);
		setStationId(value);
	};

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Campaigns
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Advertising</a>
											</li>
											<li className="breadcrumb-item active">
												Campaigns
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a calendar view of
												advertising campaigns under your
												account. You can suspend, modify
												or add new campaigns at any
												time.
												<span
													style={{ float: "right" }}
												>
													<a
														className="btn btn-success btn-md"
														style={{
															color: "white",
														}}
														onClick={() =>
															handleShow()
														}
													>
														<i className="fa fa-plus"></i>{" "}
														Add Campaign
													</a>
												</span>
											</p>

											<div
												style={{
													width: "65rem",
													marginLeft: "auto",
													marginRight: "auto",
													marginTop: "5rem",
												}}
											>
												<FullCalendar
													plugins={[
														interactionPlugin,
														dayGridPlugin,
														timeGridPlugin,
													]}
													initialView="dayGridMonth"
													weekends={true}
													events={calendarEvents}
													eventContent={
														renderEventContent
													}
													//selectable={true}
													eventClick={editEvent}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal show={show} onHide={handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Add A New Campaign</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form
							id="create_campaign_form"
							onSubmit={createCampaign}
						>
							<Tabs defaultActiveKey="details" className="mb-3">
								<Tab eventKey="details" title="Details">
									<div className="row">
										<div className="col-12">
											<div className="card m-b-30">
												<div className="card-body">
													<div className="form-group row">
														<div className="col-sm-6">
															<label>
																Campaign Name
															</label>
															<input
																className="form-control"
																type="text"
																id="input-campaign-name"
																value={name}
																onChange={(e) =>
																	setName(
																		e.target
																			.value
																	)
																}
															/>
														</div>
														<div className="col-sm-6">
															<label>Type</label>
															<select
																className="form-control"
																onChange={(e) =>
																	setCampaignType(
																		e.target
																			.value
																	)
																}
															>
																<option>
																	Select a
																	type
																</option>
																<option value="IDLE SCREEN">
																	Idle Screen
																	(Multimedia)
																</option>
																<option value="CHARGE SCREEN">
																	Charging
																	Screen
																	(Text)
																</option>
															</select>
														</div>
													</div>
													<div className="form-group row m-t-30">
														<div className="col-sm-6">
															<label>
																Station
															</label>
															<select
																className="form-control"
																multiple
																onChange={(e) =>
																	handleStationChange(
																		e
																	)
																}
															>
																<option value="">
																	Select a
																	Station
																</option>
																{stations.stations.map(
																	(
																		station,
																		index
																	) => (
																		<option
																			key={
																				index
																			}
																			value={
																				station.id
																			}
																		>
																			{
																				station.name
																			}
																		</option>
																	)
																)}
															</select>
														</div>
														<div className="col-sm-6"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="dates" title="Dates">
									<div className="row">
										<div className="col-12">
											<div className="card m-b-30">
												<div className="card-body">
													<div className="form-group row">
														<div className="col-sm-6">
															<label>
																Start Date
															</label>
															<input
																class="form-control"
																type="date"
																value={
																	startDate
																}
																id="input-start-date"
																onChange={(e) =>
																	setStartDate(
																		e.target
																			.value
																	)
																}
															/>
														</div>
														<div className="col-sm-6">
															<label>
																End Date
															</label>
															<input
																class="form-control"
																type="date"
																value={endDate}
																id="input-end-date"
																onChange={(e) =>
																	setEndDate(
																		e.target
																			.value
																	)
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="run_time" title="Run time">
									<div className="row">
										<div className="col-12">
											<div className="card m-b-30">
												<div className="card-body">
													<div>
														<label
															style={{
																fontSize:
																	"13px",
															}}
														>
															All day
														</label>
														<input
															style={{
																verticalAlign:
																	"middle",
																marginLeft:
																	".5rem",
															}}
															type="checkbox"
															checked={
																allDayChecked
															}
															onChange={() =>
																setAllDayChecked(
																	!allDayChecked
																)
															}
														></input>
													</div>
													<div className="form-group row m-t-30">
														<div className="col-sm-6">
															<label>
																Start Time
															</label>
															<input
																class="form-control"
																type="time"
																disabled={
																	allDayChecked
																}
																value={
																	startTime
																}
																id="input-start-time"
																onChange={(e) =>
																	setStartTime(
																		e.target
																			.value +
																			":00"
																	)
																}
															/>
														</div>
														<div className="col-sm-6">
															<label>
																End Time
															</label>
															<input
																class="form-control"
																type="time"
																disabled={
																	allDayChecked
																}
																value={endTime}
																id="input-end-time"
																onChange={(e) =>
																	setEndTime(
																		e.target
																			.value +
																			":00"
																	)
																}
															/>
														</div>
													</div>
													<div className="form-group row m-t-30">
														<div className="col-sm-6">
															<label>
																Recurring
															</label>
															<select
																className="form-control"
																id="recurring-select"
																onChange={(e) =>
																	setRecurring(
																		e.target
																			.value
																	)
																}
															>
																<option value="EVERY_DAY">
																	Every day
																</option>
																<option value="WEEKLY">
																	Weekly
																</option>
																<option value="MONTHLY">
																	Monthly
																</option>
															</select>
														</div>
														<div className="col-sm-6"></div>
													</div>
													{(() => {
														if (
															recurring ==
															"WEEKLY"
														) {
															return (
																<>
																	<div
																		style={{
																			marginTop:
																				"2rem",
																		}}
																	>
																		<label
																			style={{
																				fontSize:
																					"13px",
																			}}
																		>
																			Days
																			of
																			the
																			week
																		</label>
																	</div>
																	<div
																		style={{
																			marginTop:
																				"1rem",
																		}}
																	>
																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				sundayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(sundayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setSundayChecked(
																					!sundayChecked
																				);
																			}}
																		>
																			Sunday
																		</button>

																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				mondayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(mondayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setMondayChecked(
																					!mondayChecked
																				);
																			}}
																		>
																			Monday
																		</button>

																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				tuesdayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(tuesdayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setTuesdayChecked(
																					!tuesdayChecked
																				);
																			}}
																		>
																			Tuesday
																		</button>

																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				wednesdayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(wednesdayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setWednesdayChecked(
																					!wednesdayChecked
																				);
																			}}
																		>
																			Wednesday
																		</button>

																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				thursdayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(thursdayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setThursdayChecked(
																					!thursdayChecked
																				);
																			}}
																		>
																			Thursday
																		</button>

																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				fridayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(fridayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setFridayChecked(
																					!fridayChecked
																				);
																			}}
																		>
																			Friday
																		</button>

																		<input
																			type="checkbox"
																			class="btn-check"
																			style={{
																				visibility:
																					"hidden",
																			}}
																			id="btn-check-outlined"
																			checked={
																				saturdayChecked
																			}
																			onChange={() => {}}
																		/>
																		<button
																			className={
																				"btn " +
																				(saturdayChecked
																					? "btn-primary"
																					: "btn-outline-primary")
																			}
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				setSaturdayChecked(
																					!saturdayChecked
																				);
																			}}
																		>
																			Saturday
																		</button>
																	</div>
																</>
															);
														} else if (
															recurring ==
															"MONTHLY"
														) {
															return (
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>
																			Select
																			day
																		</label>
																		<select
																			className="form-control"
																			value={
																				daySelect
																			}
																			onChange={(
																				e
																			) =>
																				setDaySelect(
																					e
																						.target
																						.value
																				)
																			}
																		>
																			<option value="date">
																				{
																					dayDescription
																				}
																			</option>
																		</select>
																	</div>
																	<div className="col-sm-6"></div>
																</div>
															);
														}
													})()}
												</div>
											</div>
										</div>
									</div>
								</Tab>
								{campaignType === "IDLE SCREEN" ? (
									<Tab eventKey="media" title="Media">
										<div style={{ textAlign: "center" }}>
											{media.data.length > 0 &&
											media.loading !== "pending" ? (
												media.data.map((m) => {
													return (
														<Card
															style={{
																width: "10rem",
																display:
																	"inline-block",
																textAlign:
																	"center",
															}}
														>
															<Card.Img
																variant="top"
																style={{
																	maxWidth:
																		"140px",
																	padding:
																		"7px",
																}}
																src={
																	"data:image/png;base64," +
																	m.base64
																}
															/>
															<Card.Body
																style={{
																	textAlign:
																		"center",
																}}
															>
																<input
																	style={{
																		marginTop:
																			"15px",
																	}}
																	type="checkbox"
																	class="image-select"
																	value={m.id}
																/>
															</Card.Body>
														</Card>
													);
												})
											) : (
												<div
													style={{
														textAlign: "center",
														height: "25rem",
													}}
												>
													<Oval
														stroke="#30419b"
														width="5rem"
														height="5rem"
														style={{
															marginTop: "8rem",
														}}
													/>
												</div>
											)}
										</div>
									</Tab>
								) : (
									<></>
								)}

								{campaignType === "CHARGE SCREEN" ? (
									<Tab
										eventKey="charge_screen_text"
										title="Charge Screen"
									>
										{/*<div className="form-group row m-t-30">
                      <div className="col-sm-6">
                        <label>Charge Screen Text</label>
                        <input class="form-control" type="text" value={chargeScreenText} id="input-charge-screen-text" onChange={(e) => setChargeScreenText(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                      </div>
                    </div>*/}

										<span
											style={{
												display: "block",
												marginTop: "2rem",
												fontSize: "14px",
											}}
										>
											Enter up to 10 texts to display on
											the charging screen
										</span>
										<div className="form-group row m-t-30">
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText1}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText1(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText2}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText2(
															e.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText3}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText3(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText4}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText4(
															e.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText5}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText5(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText6}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText6(
															e.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText7}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText7(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText8}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText8(
															e.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText9}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText9(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-sm-6">
												<input
													class="form-control"
													type="text"
													value={chargeScreenText10}
													id="input-charge-screen-text"
													onChange={(e) =>
														setChargeScreenText10(
															e.target.value
														)
													}
												/>
											</div>
										</div>
									</Tab>
								) : (
									<></>
								)}
							</Tabs>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button
							variant="primary"
							type="submit"
							form="create_campaign_form"
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>

				<EditCampaignModal
					toggleShowEditModal={toggleShowEditModal}
					showEditModal={showEditModal}
					editId={editId}
				/>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
