import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { updateStation } from '../../features/StationsSlice'
import StateOptions from '../sections/StateOptions'

export default function EditStationModal(props) {
	const dispatch = useDispatch()
	const stations = useSelector((state) => state.stations.stations)
	const customers = useSelector((state) => state.customers.customers)
	const user = useSelector((state) => state.user)
	const [station, setStation] = useState(null)
	const [disableSubmit, setDisableSubmit] = useState(false)


	const saveStation = async (e) => {
		e.preventDefault()
		setDisableSubmit(true)

		const formData = new FormData()

		formData.append('token', user.token)
		formData.append('station_id', station.id)
		formData.append('customer_id', station.customer_id)
		formData.append('name', station.name)
		formData.append('platform_provider', station.platform_provider)
		formData.append('increment_auth_enabled', station.increment_auth_enabled)
		formData.append('address', station.address)
		formData.append('city', station.city)
		formData.append('state', station.state)
		formData.append('zipcode', station.zipcode)
		formData.append('phone', station.phone)
		formData.append('email', station.email)
		formData.append('eula', station.eula)
		formData.append('tos', station.tos)
		formData.append('default_preauth', station.default_preauth)
		formData.append('tax_rate_federal', station.tax_rate_federal)
		formData.append('tax_rate_state', station.tax_rate_state)
		formData.append('tax_rate_county', station.tax_rate_county)
		formData.append('tax_rate_city', station.tax_rate_city)
		formData.append('price_per_kwh', station.price_per_kwh)
		formData.append('use_time_based_pricing', station.use_time_based_pricing)
		formData.append('price_per_hour', station.price_per_hour)

		const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/station/update', formData, {
			headers: {
				Authorization: 'Bearer ' + user.token
			}
		}).catch((error) => {
			toast.error('Something went wrong', {
				position: "top-right",
				autoClose: 8000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		})

		setTimeout(() => {
			setDisableSubmit(false)
		}, 3000)

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			dispatch(updateStation(response.data.data))
			props.toggleShowEditModal()

		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	}

	useEffect(() => {
		/* Get station from state */
		let station = stations.find(obj => {
			return obj.id == props.editId
		})

		setStation(station)
	}, [props.editId])

	return (
		<>
			<Modal show={props.showEditModal} onHide={props.toggleShowEditModal} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Edit Station</Modal.Title>
				</Modal.Header>
				<Form id="edit_station_form" onSubmit={saveStation}>
					<Modal.Body>
						{(() => {
							if (station) {
								return (
									<>
										<Tabs defaultActiveKey="details" className="mb-3">
											<Tab eventKey="details" title="Details">
												<div className="row">
													<div className="col-12">
														<div className="card m-b-30">
															<div className="card-body">
																<div className="form-group row">
																	{user.data.role == "SUPER ADMIN" &&
																		<div className="col-sm-6">
																			<label>Customer</label>
																			<select className="form-control" value={station.customer_id} onChange={(e) => { setStation({ ...station, customer_id: e.target.value }) }}>
																				<option>Select a customer</option>
																				{
																					customers.map((customer, index) => {
																						return (
																							<option key={index} value={customer.id}>{customer.company_name}</option>
																						)
																					})
																				}
																			</select>
																		</div>
																	}
																	<div className="col-sm-6">
																		<label>Name</label>
																		<input className="form-control" type="text" id="input-name" value={station.name} onChange={(e) => { setStation({ ...station, name: e.target.value }) }} />
																	</div>
																</div>
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>Address</label>
																		<input className="form-control" type="text" id="input-address" value={station.address} onChange={(e) => { setStation({ ...station, address: e.target.value }) }} />
																	</div>
																	<div className="col-sm-6">
																		<label>City</label>
																		<input className="form-control" type="text" id="input-city" value={station.city} onChange={(e) => { setStation({ ...station, city: e.target.value }) }} />
																	</div>
																</div>
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>State</label>
																		<select className="form-control" value={station.state} onChange={(e) => { setStation({ ...station, state: e.target.value }) }} >
																			<StateOptions />
																		</select>
																	</div>
																	<div className="col-sm-6">
																		<label>Zipcode</label>
																		<input className="form-control" type="text" id="input-zip" value={station.zipcode} onChange={(e) => { setStation({ ...station, zipcode: e.target.value }) }} />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="platform" title="Platform">
												<div className="row">
													<div className="col-12">
														<div className="card m-b-30">
															<div className="card-body">
																<div className="form-group row">
																	<div className="col-sm-6">
																		<label>Platform</label>
																		<select className="form-control" value={station.platform_provider} onChange={(e) => { setStation({ ...station, platform_provider: e.target.value }) }}>
																			<option>Select a platform</option>
																			<option value="DataCap">DataCap</option>
																			<option value="AMP360">AMP360</option>
																		</select>
																	</div>
																	<div className="col-sm-6">
																		<label>Default Preauth</label>
																		<input className="form-control" type="number" id="input-default-preauth" value={station.default_preauth} onChange={(e) => { setStation({ ...station, default_preauth: e.target.value }) }} />
																	</div>
																</div>
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>Increment Auth</label>
																		<select className="form-control" value={station.increment_auth_enabled} onChange={(e) => { setStation({ ...station, increment_auth_enabled: e.target.value }) }}>
																			<option>Select an option</option>
																			<option value="1">Enabled</option>
																			<option value="0">Disabled</option>
																		</select>
																	</div>
																	<div className="col-sm-6">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="taxes" title="Taxes">
												<div className="row">
													<div className="col-12">
														<div className="card m-b-30">
															<div className="card-body">
																<div className="form-group row">
																	<div className="col-sm-6">
																		<label>Tax Rate % (Federal)</label>
																		<input className="form-control" type="number" id="input-federal-tax" value={station.tax_rate_federal} onChange={(e) => { setStation({ ...station, tax_rate_federal: e.target.value }) }} />
																	</div>
																	<div className="col-sm-6">
																		<label>Tax Rate % (State)</label>
																		<input className="form-control" type="number" id="input-state-tax" value={station.tax_rate_state} onChange={(e) => { setStation({ ...station, tax_rate_state: e.target.value }) }} />
																	</div>
																</div>
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>Tax Rate % (County)</label>
																		<input className="form-control" type="number" id="input-county-tax" value={station.tax_rate_county} onChange={(e) => { setStation({ ...station, tax_rate_county: e.target.value }) }} />
																	</div>
																	<div className="col-sm-6">
																		<label>Tax Rate % (City)</label>
																		<input className="form-control" type="number" id="input-city-tax" value={station.tax_rate_city} onChange={(e) => { setStation({ ...station, tax_rate_city: e.target.value }) }} />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="support" title="Support">
												<div className="row">
													<div className="col-12">
														<div className="card m-b-30">
															<div className="card-body">
																<div className="form-group row">
																	<div className="col-sm-6">
																		<label>Telephone</label>
																		<input className="form-control" type="tel" placeholder="1-(555)-555-5555" id="input-tel" value={station.phone} onChange={(e) => { setStation({ ...station, phone: e.target.value }) }} />
																	</div>
																	<div className="col-sm-6">
																		<label>Email</label>
																		<input className="form-control" type="email" id="input-email" value={station.email} onChange={(e) => { setStation({ ...station, email: e.target.value }) }} />
																	</div>
																</div>
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>URL</label>
																		<input className="form-control" type="url" id="input-website" />
																	</div>
																	<div className="col-sm-6">
																		<label>ToS URL</label>
																		<input className="form-control" type="url" id="input-tos" value={station.tos} onChange={(e) => { setStation({ ...station, tos: e.target.value }) }} />
																	</div>
																</div>
																<div className="form-group row m-t-30">
																	<div className="col-sm-6">
																		<label>EULA URL</label>
																		<input className="form-control" type="url" id="input-eula" value={station.eula} onChange={(e) => { setStation({ ...station, eula: e.target.value }) }} />
																	</div>
																	<div className="col-sm-6">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="pricing" title="Pricing">
												<div className="row">
													<div className="col-12">
														<div className="card m-b-30">
															<div className="card-body">
																<div className="form-group row">
																	<span>Time Based Pricing</span>
																	<input style={{ marginLeft: "2rem" }} type="checkbox" checked={station.use_time_based_pricing == 1 ? true : false} onClick={(e) => setStation({ ...station, use_time_based_pricing: station.use_time_based_pricing == 1 ? 0 : 1 })}></input>
																</div>
																{station.use_time_based_pricing == 1 ?
																	<div className="form-group row">
																		<div className="col-sm-6">
																			<label>Price Per Hour</label>
																			<input className="form-control" type="number" id="input-price-per-hour" value={station.price_per_hour} onChange={(e) => { setStation({ ...station, price_per_hour: e.target.value }) }} />
																		</div>
																		<div className="col-sm-6">
																		</div>
																	</div>
																	:
																	<div className="form-group row">
																		<div className="col-sm-6">
																			<label>Price Per kWh</label>
																			<input className="form-control" type="number" id="input-price-per-kwh" value={station.price_per_kwh} onChange={(e) => { setStation({ ...station, price_per_kwh: e.target.value }) }} />
																		</div>
																		<div className="col-sm-6">
																		</div>
																	</div>
																}
															</div>
														</div>
													</div>
												</div>
											</Tab>
										</Tabs >
									</>
								)
							}
						})()}
					</Modal.Body>
					<Modal.Footer>
						<button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowEditModal}>Close</button>
						<button disabled={disableSubmit} type="submit" form="edit_station_form" className="btn btn-success waves-effect waves-light">Save changes</button>
					</Modal.Footer>
				</Form>
			</Modal >
		</>
	)
}
