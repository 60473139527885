/* eslint-disable */
import React, { useState, useEffect } from "react"
import Menu from "../sections/Menu"
import MobileMenuButton from "../buttons/MobileMenuButton"
import NavbarRight from "../sections/NavbarRight"
import $ from 'jquery'
import JSZip from 'jszip';
window.JSZip = JSZip;
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-responsive';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import DataTable from 'datatables.net-bs4';
import useScriptImporter from "../hooks/useScriptImporter"
import { useSelector, useDispatch } from "react-redux"
import AddChargerModal from "../modals/AddChargerModal"
import { clearChargers, getChargers } from "../../features/ChargersSlice"
import StatusTD from "../sections/StatusTD"
import EditChargerModal from "../modals/EditChargerModal"
import { getStations } from "../../features/StationsSlice"
import DeleteModal from "../modals/DeleteModal"
import TopBar from "../sections/TopBar"
import Helpers from "../../assets/js/Helpers"
import { getCustomers } from "../../features/CustomersSlice"

DataTable.Buttons.pdfMake(pdfMake);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Chargers() {
  const scriptsImported = useScriptImporter()
  const chargers = useSelector((state) => state.chargers.chargers)
  const [chargerList, setChargerList] = useState([])
  const customers = useSelector((state) => state.customers.customers)
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [showAddModal, setShowAddModal] = useState(false)
  const [keyIncrementer, setKeyIncrementer] = useState(0)
  const [editId, setEditId] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const targetColumns = user.data.role == "SUPER ADMIN" ? [-1, -2] : [-1]

  const toggleShowAddModal = () => {
    setShowAddModal(!showAddModal)
  }

  const toggleShowEditModal = () => {
    setShowEditModal(!showEditModal)
  }

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  useEffect(() => {
    dispatch(getChargers())
    dispatch(getStations())


    if (customers.length === 0) {
      dispatch(getCustomers())
    }
    return () => {
      dispatch(clearChargers())
    }
  }, [])

  useEffect(() => {
    $('#datatable').DataTable({
      "pageLength": 10,
      "lengthChange": true,
      "lengthMenu": [10, 20, 50, 100, 200, 500],
      "dom": 'Blfrtip',
      "columnDefs": [
        {
          "targets": targetColumns,
          "className": 'all'
        }
      ],
      "buttons": [
        { extend: 'copyHtml5', filename: "chargers", title: 'Chargers', },
        { extend: 'excelHtml5', filename: "chargers", title: 'Chargers', },
        { extend: 'csvHtml5', filename: "chargers", title: 'Chargers', },
        { extend: 'pdfHtml5', filename: "chargers", title: 'Chargers', orientation: 'landscape', },
      ],

    });
  }, [keyIncrementer])

  useEffect(() => {
    /**
     * Using a key and an incrementing it when this state
     * changes will cause the element to be removed and
     * re-rendered, this way we can refresh the datatable correctly
     */
    setKeyIncrementer(keyIncrementer + 1);
    setChargerList(chargers)
  }, [chargers])

  return (
    <>
      <div id="wrapper">
        <TopBar />
        <Menu />

        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <h4 className="page-title">Your Chargers</h4>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                      <li className="breadcrumb-item"><a>Chargers</a></li>
                      <li className="breadcrumb-item active">Listing</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card m-b-30">
                    <div className="card-body">
                      <p className="sub-title">This is a listing of all chargers per account. You can filter on the station column to list all devices within a station.<span style={{ float: "right" }}><a className="btn btn-success btn-md" style={{ color: "white" }} onClick={() => toggleShowAddModal()}><i className="fa fa-plus"></i> Add</a></span></p>
                      <div key={keyIncrementer}>
                        <table id="datatable" className="table table-bordered dt-responsive nowrap" style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }} >
                          <thead>
                            <tr>
                              {user.data.role == "SUPER ADMIN" ? <th>Customer</th> : <></>}
                              <th>Serial #</th>
                              <th>Station</th>
                              <th>ID Tag</th>
                              <th>Status</th>
                              <th>EVSE Power Limit (W)</th>
                              <th>EVSE Current Limit (mA)</th>
                              <th>EVSE Voltage Limit (mV)</th>
                              <th>Edit</th>
                              {
                                user.data.role == "SUPER ADMIN" ?
                                  <th>Delete</th> : <></>
                              }
                            </tr>
                          </thead>

                          <tbody>
                            {
                              chargerList.map((charger, index) => {
                                return (
                                  <tr key={index} style={{ background: charger.status == 0 ? 'lightpink' : '' }}>
                                    {user.data.role == "SUPER ADMIN" ? <td>{Helpers.getCustomerName(charger.merchant_id, customers)}</td> : <></>}
                                    <td>{charger.serial_no}</td>
                                    <td>{charger.station_name}</td>
                                    <td>{charger.id_tag}</td>
                                    <StatusTD status={charger.status} />
                                    <td>{charger.EVSE_power_limit_W}</td>
                                    <td>{charger.EVSE_current_limit_mA}</td>
                                    <td>{charger.EVSE_voltage_limit_mV}</td>
                                    <td style={{ textAlign: "center" }}><button type="button" className="btn btn-primary" onClick={() => { toggleShowEditModal(); setEditId(charger.id); }}><i className="mdi mdi-pencil text-white"></i></button></td>
                                    {
                                      user.data.role == "SUPER ADMIN" ? <td style={{ textAlign: "center" }}><button type="button" className="btn btn-danger" onClick={() => { toggleShowDeleteModal(); setDeleteId(charger.id) }}><i className="mdi mdi-trash-can-outline text-white"></i></button></td>
                                        :
                                        <></>
                                    }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AddChargerModal toggleShowAddModal={toggleShowAddModal} showAddModal={showAddModal} />
          <EditChargerModal toggleShowEditModal={toggleShowEditModal} showEditModal={showEditModal} editId={editId} />
          <DeleteModal toggleShowDeleteModal={toggleShowDeleteModal} showDeleteModal={showDeleteModal} deleteId={deleteId} resourceType={"CHARGER"} />
        </div>


        <footer className="footer">
          © 2023 Powered by Charge To Charge <span className="d-none d-sm-inline-block"></span>
        </footer>

      </div>
    </>
  )
}
