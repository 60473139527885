import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getMedia = createAsyncThunk('media/getMedia', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/media/all', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    data: [],
    loading: ''
  },
  reducers: {
    setMedia: (state, action) => {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMedia.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getMedia.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.loading = ''
    })
    builder.addCase(getMedia.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setMedia } = mediaSlice.actions

export default mediaSlice.reducer