import React, { useEffect, useState } from "react";
import Menu from "../sections/Menu";
import { useLocation } from "react-router";
import useScriptImporter from "../hooks/useScriptImporter";
import TopBar from "../sections/TopBar";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Invoice() {
	const scriptsImported = useScriptImporter();
	const user = useSelector((state) => state.user);
	const [show, setShow] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [test, setTest] = useState(false);
	const [payment, setPayment] = useState(false);
	let { state } = useLocation();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const confirmRefund = async () => {
		setDisableSubmit(true);

		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("payment_id", payment.id);
		formData.append("amount", payment.amount);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/payment/refund",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			handleClose();
			setDisableSubmit(false);
			setPayment(response.data.data);
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}

			handleClose();
			setDisableSubmit(false);
		}
	};

	const printWindow = () => {
		window.print();
	};

	useEffect(() => {
		setPayment(state.session.payment);
	}, []);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4
											style={{ display: "inline-block" }}
											className="page-title"
										>
											Invoice
										</h4>
										{payment.refund_status ===
										"REFUNDED" ? (
											<></>
										) : (
											<>
												<button
													style={{
														display: "inline-block",
														marginLeft: "1rem",
													}}
													className="btn btn-danger"
													onClick={() => handleShow()}
												>
													Refund
												</button>
											</>
										)}
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Invoice</a>
											</li>
											<li className="breadcrumb-item active">
												Invoice Detail
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<div className="row">
												<div className="col-12">
													<div className="invoice-title">
														<h4 className="float-right font-16">
															<strong>
																Order #{" "}
																{
																	state
																		.session
																		.id
																}
															</strong>
														</h4>
														<h6 className="m-t-0">
															<img
																src="assets/images/global.png"
																className="logo"
																alt="client logo"
																height="24"
															/>
															<br />
															<br />
															{
																state.session
																	.customer
																	.company_name
															}
															<br />
															{
																state.session
																	.customer
																	.address
															}
															<br />
															{
																state.session
																	.customer
																	.city
															}
															,{" "}
															{
																state.session
																	.customer
																	.state
															}{" "}
															{
																state.session
																	.customer
																	.zipcode
															}
															<br />
															{
																state.session
																	.customer
																	.telephone
															}
														</h6>
													</div>
													<hr />
													<div
														className="row"
														style={{
															marginBottom:
																"4rem",
														}}
													>
														<div className="col-6">
															<address>
																<strong>
																	Billed To:
																</strong>
																<br />
																{
																	payment.cardholder_name
																}
																<br />
															</address>
														</div>
													</div>
													<div className="row">
														<div className="col-6">
															<address>
																<strong>
																	Payment
																	Method:
																</strong>
																<br />
																{
																	payment.card_type
																}{" "}
																ending{" "}
																{
																	payment.masked_pan
																}
																<br />
															</address>
														</div>
														<div className="col-6 text-right">
															<address>
																<strong>
																	Order Date:
																</strong>
																<br />
																{dayjs
																	.utc(
																		state
																			.session
																			.created_at
																	)
																	.tz(
																		Intl.DateTimeFormat().resolvedOptions()
																			.timeZone
																	)
																	.format(
																		"MM/DD/YYYY h:m a"
																	)}
																<br />
																<br />
															</address>
														</div>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<div className="panel panel-default">
														<div className="p-2">
															<h3 className="panel-title font-20">
																<strong>
																	Order
																	summary
																</strong>
															</h3>
														</div>
														<div className="">
															<div className="table-responsive">
																<table className="table">
																	<thead>
																		<tr>
																			<td>
																				<strong>
																					kWh
																				</strong>
																			</td>
																			<td className="text-center">
																				<strong>
																					Price
																					Per
																					kWh
																				</strong>
																			</td>
																			<td className="text-center">
																				<strong></strong>
																			</td>
																			<td className="text-right">
																				<strong>
																					Totals
																				</strong>
																			</td>
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td>
																				{
																					state
																						.session
																						.energy_Wh
																				}
																			</td>
																			<td className="text-center">
																				$
																				{
																					state
																						.session
																						.price_per_kwh
																				}
																			</td>
																			<td className="text-center"></td>
																			<td className="text-right">
																				$
																				{
																					state
																						.session
																						.subtotal
																				}
																			</td>
																		</tr>
																		<tr>
																			<td className="thick-line"></td>
																			<td className="thick-line"></td>
																			<td className="thick-line text-center">
																				<strong
																					style={{
																						float: "right",
																					}}
																				>
																					Subtotal
																				</strong>
																			</td>
																			<td className="thick-line text-right">
																				$
																				{
																					state
																						.session
																						.subtotal
																				}
																			</td>
																		</tr>
																		<tr>
																			<td className="no-line"></td>
																			<td className="no-line"></td>
																			<td className="no-line text-center">
																				<strong
																					style={{
																						float: "right",
																					}}
																				>
																					Tax
																				</strong>
																			</td>
																			<td className="no-line text-right">
																				$
																				{
																					state
																						.session
																						.tax
																				}
																			</td>
																		</tr>
																		<tr>
																			<td className="no-line"></td>
																			<td className="no-line"></td>
																			<td className="no-line text-center">
																				<strong
																					style={{
																						float: "right",
																					}}
																				>
																					Total
																				</strong>
																			</td>
																			<td className="no-line text-right">
																				<h4 className="m-0">
																					$
																					{
																						state
																							.session
																							.total
																					}
																				</h4>
																			</td>
																		</tr>
																		{payment.refund_status ===
																		"REFUNDED" ? (
																			<>
																				<tr>
																					<td className="no-line"></td>
																					<td className="no-line"></td>
																					<td className="no-line text-center">
																						<strong
																							style={{
																								float: "right",
																								color: "red",
																							}}
																						>
																							Refund
																						</strong>
																					</td>
																					<td className="no-line text-right">
																						<h4
																							style={{
																								color: "red",
																							}}
																							className="m-0"
																						>
																							$
																							{
																								state
																									.session
																									.total
																							}
																						</h4>
																					</td>
																				</tr>
																			</>
																		) : (
																			<>

																			</>
																		)}
																	</tbody>
																</table>
															</div>

															<div className="d-print-none mo-mt-2">
																<div className="float-right">
																	<a
																		onClick={() =>
																			printWindow()
																		}
																		className="btn btn-success waves-effect waves-light"
																	>
																		<i
																			style={{
																				color: "white",
																			}}
																			className="fa fa-print"
																		></i>
																	</a>
																	<a
																		href="#"
																		className="btn btn-primary waves-effect waves-light"
																		style={{
																			marginLeft:
																				"10px",
																		}}
																	>
																		Send
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<br />
						</div>
					</div>
				</div>

				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Confirm</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Are you sure you want to refund this payment?
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							disabled={disableSubmit}
							variant="danger"
							onClick={() => confirmRefund()}
						>
							Refund
						</Button>
					</Modal.Footer>
				</Modal>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
