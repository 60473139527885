import React, { useState, useEffect } from "react";
import TopBar from "../sections/TopBar";
import Menu from "../sections/Menu";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getMerchantSettings } from "../../features/MerchantSettingsSlice";

export default function Settings() {
	const dispatch = useDispatch();
	const { user, merchantSettings } = useSelector((state) => state);
	const [telephone, setTelephone] = useState("");
	const [disableSubmit, setDisableSubmit] = useState(false);

	const updateSettings = async (e) => {
		e.preventDefault();
		setDisableSubmit(true);

		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("telephone", telephone);
		formData.append("merchant_id", user.data.merchant_id);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/merchant/settings/update",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		setTimeout(() => {
			setDisableSubmit(false);
		}, 3000);

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			//dispatch(getChargers())
			//props.toggleShowAddModal()
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	useEffect(() => {
		dispatch(getMerchantSettings());
	}, []);

	useEffect(() => {
		if (Object.keys(merchantSettings.settings).length > 0) {
			setTelephone(
				merchantSettings.settings.data[0]?.telephone
					? merchantSettings.settings.data[0]?.telephone
					: ""
			);
		}
	}, [merchantSettings]);
	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Settings
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>User</a>
											</li>
											<li className="breadcrumb-item active">
												Settings
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<Form
												id="update_settings_form"
												onSubmit={updateSettings}
											>
												<div className="form-group row m-t-30">
													<div className="col-sm-4">
														<label>Telephone</label>
														<input
															className="form-control"
															type="text"
															id="input-address"
															value={telephone}
															onChange={(e) =>
																setTelephone(
																	e.target
																		.value
																)
															}
														/>
													</div>
													<div className="col-sm-4"></div>
												</div>
												<div className="form-group row m-t-30">
													<div className="col-sm-4">
														<button
															type="submit"
															disabled={
																disableSubmit
															}
															style={{
																width: "5rem",
																marginTop:
																	"2rem",
															}}
															className="btn btn-success"
														>
															Save
														</button>
													</div>
													<div className="col-sm-4"></div>
												</div>
											</Form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
