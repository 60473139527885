/* eslint-disable */
import React, { useEffect, useReducer, useState } from "react";
import Menu from "../sections/Menu";
import MobileMenuButton from "../buttons/MobileMenuButton";
import Charts from "../sections/Charts";
import NavbarRight from "../sections/NavbarRight";
import useScriptImporter from "../hooks/useScriptImporter";
import { useSelector } from "react-redux";
import Icon from '@mdi/react';
import { mdiLightningBolt, mdiSquareOutline, mdiCubeOutline, mdiCurrencyUsd, mdiCarElectric, mdiNaturePeople } from '@mdi/js';
import { Link } from "react-router-dom";
import TopBar from "../sections/TopBar";
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

const rangeStyle = {
  paddingLeft: "1rem",
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  border: "none",
  marginBottom: "25px"
}

const Dashboard = () => {
  const scriptsImported = useScriptImporter();
  const chargeSessions = useSelector((state) => state.chargeSessions.sessions)
  const stations = useSelector((state) => state.stations.stations)
  const user = useSelector((state) => state.user)
  const [range, setRange] = useState('')
  const [cs, setCs] = useState(chargeSessions)

  const changeStats = (timeframe) => {
    setRange(timeframe)
    let cs_copy = [...chargeSessions]
    let currentDay = dayjs().day();
    let startOfWeek = dayjs(dayjs().subtract(currentDay, 'days').format('YYYY-MM-DD 00:00:01')) // Sunday at midnight
    let startOfMonth = dayjs().startOf('month')
    let startOfYear = dayjs().startOf('year')
    let rangeDate;

    switch (timeframe) {
      case "THIS WEEK":
        rangeDate = startOfWeek
        break;
      case "THIS MONTH":
        rangeDate = startOfMonth
        break;
      case "THIS YEAR":
        rangeDate = startOfYear
        break;
      default:
        rangeDate = startOfWeek

    }

    for (var i = cs_copy.length - 1; i >= 0; i--) {
      if (dayjs(cs_copy[i].start_time).isBefore(rangeDate)) {
        cs_copy.splice(i, 1);
      }
    }
    setCs(cs_copy)
  }

  useEffect(() => {
    // Trigger state update and get data for "THIS WEEK". This will also initialize the graphs
    changeStats("THIS WEEK")
  }, [chargeSessions])

  const progressPercent = cs.length
  const total_revenue = (cs.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.payment.amount), 0)).toFixed(2)
  let avg_kwh = (cs.reduce((accumulator, currentValue) => accumulator + currentValue.energy_Wh, 0) / cs.length).toFixed(2)
  avg_kwh = isNaN(avg_kwh) ? 0.00.toFixed(2) : avg_kwh

  return (
    <>
      <div id="wrapper">
        <TopBar />
        <Menu />

        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <h3 className="page-title">Your Dashboard</h3>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item"><a href="">Home</a></li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-xl-3">
                  <select className="form-control" style={rangeStyle} onChange={(e) => changeStats(e.target.value)}>
                    <option value="THIS WEEK">This week</option>
                    <option value="THIS MONTH">This month</option>
                    <option value="THIS YEAR">This year</option>
                  </select>
                </div>
              </div>

              <div className="row">

                <div className="col-sm-6 col-xl-3">
                  <div className="card">
                    <div className="card-heading p-4">
                      <div className="mini-stat-icon float-right">
                        <Icon path={mdiLightningBolt} className="bg-primary text-white" />
                      </div>
                      <div>
                        <h5 className="font-16">Charge Sessions</h5>
                      </div>
                      <h3 className="mt-4">{String(cs.length)}</h3>
                      <div className="progress mt-4" style={{ height: '4px' }}>
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: `100%` }} aria-valuenow={cs.length} aria-valuemin="0" aria-valuemax="100000"></div>
                      </div>
                      <p className="text-muted mt-2 mb-0">Previous period<span className="float-right">0</span></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-3">
                  <div className="card">
                    <div className="card-heading p-4">
                      <div className="mini-stat-icon float-right">
                        <Icon path={mdiCurrencyUsd} className="bg-success text-white" />
                      </div>
                      <div>
                        <h5 className="font-16">Total Sales</h5>
                      </div>
                      <h3 className="mt-4">${String(total_revenue)}</h3>
                      <div className="progress mt-4" style={{ height: "4px" }}>
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="73265" aria-valuemin="0" aria-valuemax="100000"></div>
                      </div>
                      <p className="text-muted mt-2 mb-0">Previous period<span className="float-right">0</span></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-3">
                  <div className="card">
                    <div className="card-heading p-4">
                      <div className="mini-stat-icon float-right">
                        <Icon path={mdiCarElectric} className="bg-warning text-white" />
                      </div>
                      <div>
                        <h5 className="font-16">Average KWH</h5>
                      </div>
                      <h3 className="mt-4">{String(avg_kwh)}</h3>
                      <div className="progress mt-4" style={{ height: '4px' }}>
                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: "100%" }} aria-valuenow="447" aria-valuemin="0" aria-valuemax="100000"></div>
                      </div>
                      <p className="text-muted mt-2 mb-0">Previous period<span className="float-right">0</span></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-3">
                  <div className="card">
                    <div className="card-heading p-4">
                      <div className="mini-stat-icon float-right">
                        <Icon path={mdiNaturePeople} className="bg-danger text-white" />
                      </div>
                      <div>
                        <h5 className="font-16">Stations</h5>
                      </div>
                      <h3 className="mt-4">{String(stations.length)}</h3>
                      <div className="progress mt-4" style={{ height: "4px" }}>
                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "100%" }} aria-valuenow="12" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <p className="text-muted mt-2 mb-0">Previous period<span className="float-right">0</span></p>
                    </div>
                  </div>
                </div>

              </div>

              <Charts chargeSessionsInRange={cs} range={range} />

              <div className="row"></div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card m-b-30">
                    <div className="card-body">
                      <h4 className="mt-0 header-title mb-4">Recent Orders</h4>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Order ID</th>
                              {user.data.role == "SUPER ADMIN" ?
                                <th scope="col">Customer</th>
                                :
                                <></>
                              }
                              <th scope="col">Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Location</th>
                              <th scope="col">Station</th>
                              <th scope="col">Date</th>
                              <th scope="col">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              cs.map((session, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{session.id}</td>
                                    {user.data.role == "SUPER ADMIN" ?
                                      <td>{session.customer.company_name}</td>
                                      :
                                      <></>
                                    }
                                    <td>{session.payment.cardholder_name}</td>

                                    {(() => {
                                      if (session.payment.refund_status == "REFUNDED") {
                                        return (
                                          <td><span style={{ width: "6rem" }} className="badge badge-danger">Refunded</span></td>
                                        )
                                      } else if (session.payment.captured_status == "CAPTURED") {
                                        return (
                                          <td><span style={{ width: "6rem" }} className="badge badge-success">Captured</span></td>
                                        )
                                      } else if (session.payment.captured_status == "PREAUTH") {
                                        return (
                                          <td><span style={{ width: "6rem" }} className="badge badge-warning">Pre-authorized</span></td>
                                        )
                                      } else if (session.payment.captured_status == "FAILED") {
                                        return (
                                          <td><span style={{ width: "6rem" }} className="badge badge-danger">Failed</span></td>
                                        )
                                      } else {
                                        return (
                                          <td><span style={{ width: "6rem" }} className="badge badge-danger">Unknown</span></td>
                                        )
                                      }
                                    })()}

                                    <td>${session.payment.amount}</td>
                                    <td>{session.station.city}</td>
                                    <td>{session.station.name}</td>
                                    <td>{dayjs.utc(session.created_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY h:m a')}</td>
                                    <td>
                                      <div>
                                        <Link to="/invoice" state={{ session: session }} className="btn btn-success btn-sm"><i className="fa fa-link"></i> View</Link>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <footer className="footer">
          © 2023 Powered by Charge To Charge <span className="d-none d-sm-inline-block"></span>
        </footer>

      </div>
    </>
  )
}

export default Dashboard