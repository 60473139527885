import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getCustomers = createAsyncThunk('customers/getCustomers', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/customers/all', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
    loading: ''
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload
    },
    updateCustomer: (state, action) => {
      /* Replace customer with updated one */
      let stateCopy = [...state.customers];
      let index = stateCopy.findIndex((obj => obj.id == action.payload.id));
      stateCopy[index] = action.payload

      state.customers = stateCopy
    },
    deleteCustomer: (state, action) => {
      let stateCopy = [...state.customers];
      let index = stateCopy.findIndex((obj => obj.id == action.payload));

      if (index > -1) {
        stateCopy.splice(index, 1);
      }

      state.customers = stateCopy
    },
    clearCustomers: (state) => {
      state.customers = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.customers = action.payload
      state.loading = ''
    })
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setCustomers, updateCustomer, deleteCustomer, clearCustomers } = customersSlice.actions

export default customersSlice.reducer