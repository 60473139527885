import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

export const getDevices = createAsyncThunk('devices/getDevices', async () => {
  const user = store.getState().user

  const formData = new FormData()
  formData.append('token', user.token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/devices/all', formData, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })

  return response.data
})

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    devices: [],
    loading: ''
  },
  reducers: {
    setDevices: (state, action) => {
      state.devices = action.payload
    },
    updateDevice: (state, action) => {
      /* Replace device with updated one */
      let stateCopy = [...state.devices];
      let index = stateCopy.findIndex((obj => obj.id == action.payload.id));
      stateCopy[index] = action.payload

      state.devices = stateCopy
    },
    deleteDevice: (state, action) => {
      let stateCopy = [...state.devices];
      let index = stateCopy.findIndex((obj => obj.id == action.payload));

      if (index > -1) {
        stateCopy.splice(index, 1);
      }

      state.devices = stateCopy
    },
    clearDevices: (state) => {
      state.devices = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDevices.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getDevices.fulfilled, (state, action) => {
      state.devices = action.payload
      state.loading = ''
    })
    builder.addCase(getDevices.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setDevices, updateDevice, deleteDevice, clearDevices } = devicesSlice.actions

export default devicesSlice.reducer