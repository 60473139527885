import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { getChargers } from '../../features/ChargersSlice';
import { getStations } from '../../features/StationsSlice';

export default function AddChargerModal(props) {
  const { user, stations } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [stationId, setStationId] = useState(0)
  const [serialNo, setSerialNo] = useState(0)
  const [idTag, setIdTag] = useState('')
  const [status, setStatus] = useState(0)
  const [EVSE_power_limit_W, setEVSE_power_limit_W] = useState(0)
  const [EVSE_current_limit_mA, setEVSE_current_limit_mA] = useState(0)
  const [EVSE_voltage_limit_mV, setEVSE_voltage_limit_mV] = useState(0)
  const [EV_power_limit_W, setEV_power_limit_W] = useState(0)
  const [EV_current_limit_mA, setEV_current_limit_mA] = useState(0)
  const [EV_voltage_limit_mV, setEV_voltage_limit_mV] = useState(0)
  const [soc, setSoc] = useState(0)
  const [startSOC, setStartSOC] = useState(0)
  const [stopSOC, setStopSOC] = useState(0)
  const [iotechaUsername, setIotechaUsername] = useState(null)
  const [iotechaPassword, setIotechaPassword] = useState(null)
  const [ipAddress, setIpAddress] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const createCharger = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('station_id', stationId)
    formData.append('serial_no', serialNo)
    formData.append('id_tag', idTag)
    formData.append('status', status)
    formData.append('EVSE_power_limit_W', EVSE_power_limit_W)
    formData.append('EVSE_current_limit_mA', EVSE_current_limit_mA)
    formData.append('EVSE_voltage_limit_mV', EVSE_voltage_limit_mV)
    formData.append('EV_power_limit_W', EV_power_limit_W)
    formData.append('EV_current_limit_mA', EV_current_limit_mA)
    formData.append('EV_voltage_limit_mV', EV_voltage_limit_mV)
    formData.append('SOC', soc)
    formData.append('start_SOC', startSOC)
    formData.append('stop_SOC', stopSOC)
    formData.append('iotecha_username', iotechaUsername)
    formData.append('iotecha_password', iotechaPassword)
    formData.append('ip_address', ipAddress)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/charger/create', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      console.log(error)
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(getChargers())
      props.toggleShowAddModal()
    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {
    if (!stations.length > 0) {
      dispatch(getStations())
    }
  }, [])

  return (
    <>
      <Modal show={props.showAddModal} onHide={props.toggleShowAddModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add A New Charger</Modal.Title>
        </Modal.Header>
        <Form id="create_charger_form" onSubmit={createCharger}>
          <Modal.Body>
            <Tabs defaultActiveKey="details" className="mb-3">
              <Tab eventKey="details" title="Details">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Station</label>
                            <select className="form-control" onChange={(e) => setStationId(e.target.value)}>
                              <option>Select a Station</option>
                              {
                                stations.stations.map((station, index) => (
                                  <option key={index} value={station.id}>{station.name}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Serial #</label>
                            <input className="form-control" type="text" id="input-serial-no" value={serialNo === 0 ? '' : serialNo} onChange={(e) => setSerialNo(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>ID Tag</label>
                            <input className="form-control" type="text" id="input-id-tag" value={idTag} onChange={(e) => setIdTag(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="limits" title="Limits">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>EVSE Power Limit (W)</label>
                            <input className="form-control" type="number" id="input-evse-power-limit" value={EVSE_power_limit_W === 0 ? '' : EVSE_power_limit_W} onChange={(e) => setEVSE_power_limit_W(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>EVSE Current Limit (mA)</label>
                            <input className="form-control" type="number" id="input-evse-current-limit" value={EVSE_current_limit_mA === 0 ? '' : EVSE_current_limit_mA} onChange={(e) => setEVSE_current_limit_mA(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>EVSE Voltage Limit (mV)</label>
                            <input className="form-control" type="number" id="input-evse-voltage-limit" value={EVSE_voltage_limit_mV === 0 ? '' : EVSE_voltage_limit_mV} onChange={(e) => setEVSE_voltage_limit_mV(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>EV Power Limit (W)</label>
                            <input className="form-control" type="number" id="input-ev-power-limit" value={EV_power_limit_W === 0 ? '' : EV_power_limit_W} onChange={(e) => setEV_power_limit_W(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>EV Current Limit (mA)</label>
                            <input className="form-control" type="number" id="input-ev-current-limit" value={EV_current_limit_mA === 0 ? '' : EV_current_limit_mA} onChange={(e) => setEV_current_limit_mA(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>EV Voltage Limit (mV)</label>
                            <input className="form-control" type="number" id="input-ev-voltage-limit" value={EV_voltage_limit_mV === 0 ? '' : EV_voltage_limit_mV} onChange={(e) => setEV_voltage_limit_mV(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>SOC</label>
                            <input className="form-control" type="number" id="input-soc" value={soc === 0 ? '' : soc} onChange={(e) => setSoc(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Start SOC</label>
                            <input className="form-control" type="number" id="input-start-soc" value={startSOC === 0 ? '' : startSOC} onChange={(e) => setStartSOC(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>Stop SOC</label>
                            <input className="form-control" type="number" id="input-stop-soc" value={stopSOC === 0 ? '' : stopSOC} onChange={(e) => setStopSOC(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="iotecha" title="Iotecha">
              <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Username</label>
                            <input className="form-control" type="text" id="input-iotecha-username" value={iotechaUsername} onChange={(e) => setIotechaUsername(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Password</label>
                            <input className="form-control" type="text" id="input-iotecha-password" value={iotechaPassword} onChange={(e) => setIotechaPassword(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Ip Address</label>
                            <input className="form-control" type="text" id="input-ip-address" value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowAddModal}>Close</button>
            <button disabled={disableSubmit} type="submit" className="btn btn-success waves-effect waves-light">Save changes</button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
