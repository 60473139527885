import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'

export const getUser = createAsyncThunk('user/getUser', async () => {
  const token = localStorage.getItem('ctc_token')

  const response = await axios.get(process.env.REACT_APP_ENV_URL + '/api/user', {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })

  return response.data
})

export const getAssociatedUsers = createAsyncThunk('user/getAssociatedUsers', async () => {
  const token = localStorage.getItem('ctc_token')

  const response = await axios.get(process.env.REACT_APP_ENV_URL + '/api/users', {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })

  return response.data
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    token: null,
    data: null,
    associatedUsers: []
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    clearAssociatedUsers: (state) => {
      state.associatedUsers = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = ''
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = ''
    })
    builder.addCase(getAssociatedUsers.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getAssociatedUsers.fulfilled, (state, action) => {
      state.associatedUsers = action.payload
      state.loading = ''
    })
    builder.addCase(getAssociatedUsers.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setToken, clearAssociatedUsers } = userSlice.actions

export default userSlice.reducer