import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux'
import { Form, Tabs, Tab } from 'react-bootstrap'
import { getStations, setStations } from '../../features/StationsSlice';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import StateOptions from '../sections/StateOptions';
//import { getCustomers } from '../../features/CustomersSlice';

const timeBasedInput = {
  marginLeft: "1rem",
}

export default function AddStationModal(props) {
  const user = useSelector((state) => state.user)
  //const customers = useSelector((state) => state.customers.customers)
  const dispatch = useDispatch()
  //const [customer, setCustomer] = useState('')
  const [name, setName] = useState('')
  const [platform, setPlatform] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [eula, setEula] = useState('')
  const [tos, setTos] = useState('')
  const [defaultPreauth, setDefaultPreauth] = useState(0)
  const [federalTax, setFederalTax] = useState(0)
  const [stateTax, setStateTax] = useState(0)
  const [countyTax, setCountyTax] = useState(0)
  const [cityTax, setCityTax] = useState(0)
  const [enableIncrementAuth, setEnableIncrementAuth] = useState(0)
  const [chargerCount, setChargerCount] = useState(0)
  const [pricePerKwh, setPricePerKwh] = useState(0)
  const [url, setUrl] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [timeBasedIsChecked, setTimeBasedIsChecked] = useState(false)
  const [pricePerHour, setPricePerHour] = useState(0)

  const createStation = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    const formData = new FormData()

    formData.append('token', user.token)
    //formData.append('customer_id', customer)
    formData.append('name', name)
    formData.append('platform_provider', platform)
    formData.append('increment_auth_enabled', enableIncrementAuth)
    formData.append('address', address)
    formData.append('city', city)
    formData.append('state', state)
    formData.append('zipcode', zipcode)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('eula', eula)
    formData.append('tos', tos)
    formData.append('default_preauth', defaultPreauth)
    formData.append('tax_rate_federal', federalTax)
    formData.append('tax_rate_state', stateTax)
    formData.append('tax_rate_county', countyTax)
    formData.append('tax_rate_city', cityTax)
    formData.append('price_per_kwh', pricePerKwh)
    formData.append('use_time_based_pricing', timeBasedIsChecked ? 1 : 0)
    formData.append('price_per_hour', pricePerHour)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/station/create', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      console.log(error)
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(getStations())
      props.toggleShowAddModal()
    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {
    /*if (!customers.length > 0) {
      dispatch(getCustomers())
    }*/
  }, [])

  return (
    <>
      <Modal show={props.showAddModal} onHide={props.toggleShowAddModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add A New Station</Modal.Title>
        </Modal.Header>
        <Form id="create_station_form" onSubmit={createStation}>
          <Modal.Body>
            <Tabs defaultActiveKey="details" className="mb-3">
              <Tab eventKey="details" title="Details">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Name</label>
                            <input className="form-control" type="text" id="input-name" value={name} onChange={(e) => setName(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Address</label>
                            <input className="form-control" type="text" id="input-address" value={address} onChange={(e) => setAddress(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>City</label>
                            <input className="form-control" type="text" id="input-city" value={city} onChange={(e) => setCity(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>State</label>
                            <select className="form-control" onChange={(e) => setState(e.target.value)}>
                              <StateOptions />
                            </select>
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>Zipcode</label>
                            <input className="form-control" type="text" id="input-zip" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="platform" title="Platform">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Platform</label>
                            <select className="form-control" onChange={(e) => setPlatform(e.target.value)}>
                              <option>Select a platform</option>
                              <option value="DataCap">DataCap</option>
                              <option value="AMP360">AMP360</option>
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Default Preauth</label>
                            <input className="form-control" type="number" id="input-default-preauth" value={defaultPreauth} onChange={(e) => setDefaultPreauth(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>Increment Auth</label>
                            <select className="form-control" onChange={(e) => setEnableIncrementAuth(e.target.value)}>
                              <option>Select an option</option>
                              <option value="1">Enabled</option>
                              <option value="0">Disabled</option>
                            </select>
                          </div>
                          <div className="col-sm-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="taxes" title="Taxes">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Tax Rate % (Federal)</label>
                            <input className="form-control" type="number" id="input-federal-tax" value={federalTax} onChange={(e) => setFederalTax(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Tax Rate % (State)</label>
                            <input className="form-control" type="number" id="input-state-tax" value={stateTax} onChange={(e) => setStateTax(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>Tax Rate % (County)</label>
                            <input className="form-control" type="number" id="input-county-tax" value={countyTax} onChange={(e) => setCountyTax(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Tax Rate % (City)</label>
                            <input className="form-control" type="number" id="input-city-tax" value={cityTax} onChange={(e) => setCityTax(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="support" title="Support">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Telephone</label>
                            <input className="form-control" type="tel" placeholder="1-(555)-555-5555" id="input-tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Email</label>
                            <input className="form-control" type="email" id="input-email" value={email} onChange={(e) => setEmail(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>URL</label>
                            <input className="form-control" type="url" id="input-website" value={url} onChange={(e) => setUrl(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>ToS URL</label>
                            <input className="form-control" type="url" id="input-tos" value={tos} onChange={(e) => setTos(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>EULA URL</label>
                            <input className="form-control" type="url" id="input-eula" value={eula} onChange={(e) => setEula(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="pricing" title="Pricing">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <span>Time Based Pricing</span>
                          <input style={timeBasedInput} type="checkbox" value={timeBasedIsChecked} onChange={(e) => setTimeBasedIsChecked(!timeBasedIsChecked)}></input>
                        </div>
                        {timeBasedIsChecked ?
                          <div className="form-group row" style={{ marginTop: "2rem" }}>
                            <div className="col-sm-6">
                              <label>Price Per Hour</label>
                              <input className="form-control" type="number" id="input-price-per-hour" value={pricePerHour} onChange={(e) => setPricePerHour(e.target.value)} />
                            </div>
                            <div className="col-sm-6">
                            </div>
                          </div>
                          :
                          <div className="form-group row" style={{ marginTop: "2rem" }}>
                            <div className="col-sm-6">
                              <label>Price Per kWh</label>
                              <input className="form-control" type="number" id="input-price-per-kwh" value={pricePerKwh} onChange={(e) => setPricePerKwh(e.target.value)} />
                            </div>
                            <div className="col-sm-6">
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowAddModal}>Close</button>
            <button disabled={disableSubmit} type="submit" className="btn btn-success waves-effect waves-light">Save changes</button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
