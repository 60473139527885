import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux'
import { Form, Tabs, Tab } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { getChargers } from '../../features/ChargersSlice';
import { getStations } from '../../features/StationsSlice';
import { getDevices } from '../../features/DevicesSlice';

export default function AddDeviceModal(props) {
  const { user, devices, chargers } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [chargerId, setChargerId] = useState(0)
  const [deviceType, setDeviceType] = useState('')
  const [uuid, setUuid] = useState(null)
  const [mid, setMid] = useState('')
  const [tid, setTid] = useState('')
  const [autostart, setAutostart] = useState(1)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const createDevice = async (e) => {
    e.preventDefault()
    setDisableSubmit(true)

    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('charger_id', chargerId)
    formData.append('type', deviceType)
    formData.append('uuid', uuid)
    formData.append('mid', mid)
    formData.append('tid', tid)
    formData.append('autostart', autostart)

    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/device/create', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      console.log(error)
    })

    setTimeout(() => {
      setDisableSubmit(false)
    }, 3000)

    if (response.data.code === 0) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(getDevices())
      props.toggleShowAddModal()
    } else {
      for (const [key, value] of Object.entries(response.data.errors)) {
        toast.error(`${value}`, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {
    if (!chargers.length > 0) {
      dispatch(getChargers())
    }
  }, [])

  return (
    <>
      <Modal show={props.showAddModal} onHide={props.toggleShowAddModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add A New Device</Modal.Title>
        </Modal.Header>
        <Form id="create_device_form" onSubmit={createDevice}>
          <Modal.Body>
            <Tabs defaultActiveKey="details" className="mb-3">
              <Tab eventKey="details" title="Details">
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Charger</label>
                            <select className="form-control" onChange={(e) => setChargerId(e.target.value)}>
                              <option>Select a Charger</option>
                              {
                                chargers.chargers.map((charger, index) => (
                                  <option key={index} value={charger.id}>{charger.serial_no}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Device Type</label>
                            <select className="form-control" onChange={(e) => setDeviceType(e.target.value)}>
                              <option>Select a type</option>
                              <option value="PAX IM30">PAX IM30</option>
                              <option value="AMP6500">AMP6500</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label>UUID</label>
                            <input className="form-control" type="text" id="input-uuid" value={uuid} onChange={(e) => setUuid(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label style={{ visibility: deviceType == "AMP6500" ? "hidden" : "" }} >MID</label>
                            <input style={{ visibility: deviceType == "AMP6500" ? "hidden" : "" }} className="form-control" type="text" id="input-mid" value={mid} onChange={(e) => setMid(e.target.value)} />
                          </div>
                        </div>
                        <div className="form-group row m-t-30">
                          <div className="col-sm-6">
                            <label style={{ visibility: deviceType == "AMP6500" ? "hidden" : "" }}>TID</label>
                            <input style={{ visibility: deviceType == "AMP6500" ? "hidden" : "" }} className="form-control" type="text" id="input-tid" value={tid} onChange={(e) => setTid(e.target.value)} />
                          </div>
                          <div className="col-sm-6">
                            <label>Autostart</label>
                            <select className="form-control" onChange={(e) => setAutostart(e.target.value)}>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                        </div>
                        {deviceType == "AMP6500" ?
                          <span>* You must manage this device via AMP360. Please visit their website to learn more.</span>
                          :
                          /* Keeps modal height the same */
                          <span>&nbsp;</span>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary waves-effect" onClick={props.toggleShowAddModal}>Close</button>
            <button disabled={disableSubmit} type="submit" className="btn btn-success waves-effect waves-light">Save changes</button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
