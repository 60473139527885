export default class Helpers {
  static getOrdinal(num) {
    const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" });
    const suffixes = {
      one: "st",
      two: "nd",
      few: "rd",
      other: "th"
    };

    const category = english_ordinal_rules.select(num);
    const suffix = suffixes[category];

    return (num + suffix)
  }

  /**
   * 
   * @param {string} merchantId 
   * @param {Array.<Object>} customers 
   */
  static getCustomerName(merchant_id, customers) {
    let customer = customers.find(customer => customer.merchant_id == merchant_id)

    if (customer == undefined) {
      return "";
    } else {
      return customer.company_name;
    }
  }

}