import React, { useEffect } from 'react'
import $ from 'jquery'
import '../../assets/js/metismenu.min.js'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function initMetisMenu() {

  // Make sure element exists before initializing
  if (document.getElementById('menu-list')) {
    $("#side-menu").metisMenu();

    if ($(window).width() < 1025) {
      $('body').addClass('enlarged');
    } else {
      $('body').removeClass('enlarged');
    }
  } else {
    setTimeout(initMetisMenu, 500);
  }
}

export default function Menu() {
  const activePage = useSelector(state => state.activePage.page)
  const user = useSelector(state => state.user)

  useEffect(() => {
    initMetisMenu();
  }, [])

  return (
    <div className="left side-menu">
      <div className="slimscroll-menu">
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            <li className="menu-title" id="menu-list">Menu</li>
            {user?.data?.api_user == 0 ?
              <>
                {user?.data?.role == "ADMIN" || user?.data?.role == "SUPER ADMIN" || user?.data?.role == "REPORTING" ?
                  <>
                    <li>
                      <Link to="/dashboard" className={'waves-effect ' + (activePage[0] === "DASHBOARD" ? "mm-active" : "")}>
                        <i className="icon-accelerator"></i> <span> Dashboard </span>
                      </Link>
                    </li>
                  </>
                  :
                  <></>
                }

                {user?.data?.role == "ADMIN" || user?.data?.role == "SUPER ADMIN" ?
                  <>
                    <li className={activePage[0] === "MANAGEMENT" ? "mm-active" : ""}>
                      <a className={'waves-effect ' + (activePage[0] === "MANAGEMENT" ? "mm-active" : "")}><i className="fa fa-car"></i><span> Management <span className="float-right menu-arrow"><i className="mdi mdi-chevron-right"></i></span> </span></a>
                      <ul className="submenu">
                        {user?.data?.role == "SUPER ADMIN" && <li className={activePage[1] === "CUSTOMERS" ? "mm-active" : ""}><Link to="/customers">Customers</Link></li>}
                        <li className={activePage[1] === "STATIONS" ? "mm-active" : ""}><Link to="/stations">Stations</Link></li>
                        <li className={activePage[1] === "CHARGERS" ? "mm-active" : ""}><Link to="/chargers">Chargers</Link></li>
                        <li className={activePage[1] === "DEVICES" ? "mm-active" : ""}><Link to="/devices">Devices</Link></li>
                      </ul>
                    </li>
                  </>
                  :
                  <></>
                }

                {user?.data?.role == "ADMIN" || user?.data?.role == "SUPER ADMIN" || user?.data?.role == "CAMPAIGN MANAGER" ?
                  <>
                    <li className={activePage[0] === "ADVERTISING" ? "mm-active" : ""}>
                      <a className={'waves-effect ' + (activePage[0] === "ADVERTISING" ? "mm-active" : "")}><i className="fa fa-truck-moving"></i><span> Advertising <span className="float-right menu-arrow"><i className="mdi mdi-chevron-right"></i></span> </span></a>
                      <ul className="submenu">
                        <li className={activePage[1] === "MEDIA" ? "mm-active" : ""}><Link to="/media">Media</Link></li>
                        <li className={activePage[1] === "CAMPAIGNS" ? "mm-active" : ""}><Link to="/campaigns">Campaigns</Link></li>
                        <li className={activePage[1] === "CANCELLED CAMPAIGNS" ? "mm-active" : ""}><Link to="/cancelled">Cancelled Campaigns</Link></li>
                      </ul>
                    </li></>

                  :
                  <></>
                }

                {user?.data?.role == "ADMIN" || user?.data?.role == "SUPER ADMIN" ?
                  <>
                    <li>
                      <Link to="/users" className={'waves-effect ' + (activePage[0] === "USERS" ? "mm-active" : "")}><i className="fa fa-user-friends"></i><span> Users </span></Link>
                    </li>
                  </>
                  :
                  <></>
                }
                {user?.data?.role == "ADMIN" || user?.data?.role == "SUPER ADMIN" ?
                  <>
                    {/* <li className={activePage[0] === "SUPPORT" ? "mm-active" : ""}>
                      <Link className={'waves-effect ' + (activePage[0] === "SUPPORT" ? "mm-active" : "")} to="/support"><i className="icon-mail"></i><span> Support <span className="float-right menu-arrow"></span> </span></Link>
                    </li> */}
                  </>
                  :
                  <></>
                }

              </>
              :
              <></>
            }

            {user?.data?.api_user == 1 ?
              <>
                <li>
                  <a className="waves-effect"><i className="fa fa-key"></i><span>API Keys</span></a>
                </li>
              </>
              :
              <></>
            }

          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div >
  )
}
