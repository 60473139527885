import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setToken } from "../../features/UserSlice";
import { useNavigate } from "react-router-dom";

export default function ResetPassword(props) {
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const queryParameters = new URLSearchParams(window.location.search);

	async function resetPassword(e) {
		e.preventDefault();
		console.log(queryParameters.get("email"));

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_ENV_URL}/api/password/confirm`,
				{
					token: queryParameters.get("token"),
					email: queryParameters.get("email"),
					password: password,
				}
			);
			if (response.data.code === 0) {
				toast.success("Password has been reset. Please login.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});

				navigate("/login");
			}
		} catch (error) {
			console.error(error);
			for (const [key, value] of Object.entries(
				error.response.data.errors
			)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	}

	return (
		<div className="accountbg">
			<div className="wrapper-page">
				<div className="card card-pages shadow-none">
					<div className="card-body">
						<div className="text-center m-t-0 m-b-15">
							<a
								href="pages-login.html"
								className="logo logo-admin"
							>
								<img
									src="assets/images/logo-color-tm.jpg"
									alt=""
									height="24"
								/>
							</a>
						</div>
						<h5 className="font-18 text-center">
							Reset your password
						</h5>

						<form
							className="form-horizontal"
							style={{ marginTop: "5rem" }}
							onSubmit={(e) => resetPassword(e)}
						>
							<div className="form-group">
								<div className="col-12">
									<label>Password</label>
									<input
										className="form-control"
										type="password"
										required
										placeholder="Password"
										value={password}
										onChange={(e) =>
											setPassword(e.target.value)
										}
									/>
								</div>
							</div>

							<div
								className="form-group text-center"
								style={{ marginTop: "4rem" }}
							>
								<div className="col-12">
									<button
										className="btn btn-success btn-block btn-lg waves-effect waves-light"
										type="submit"
									>
										Confirm Password
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
