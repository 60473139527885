/* eslint-disable */
import React, { useState, useEffect } from "react";
import Menu from "../sections/Menu";
import MobileMenuButton from "../buttons/MobileMenuButton";
import NavbarRight from "../sections/NavbarRight";
import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-bs4";
// import useScriptImporter from "../hooks/useScriptImporter"
import { useSelector, useDispatch } from "react-redux";
// import AddDeviceModal from "../modals/AddDeviceModal"
// import { getDevices } from "../../features/DevicesSlice"
// import ActiveBadge from "../sections/StatusTD"
// import StatusTD from "../sections/StatusTD"
// import EditDeviceModal from "../modals/EditDeviceModal"
// import DeleteModal from "../modals/DeleteModal"
import TopBar from "../sections/TopBar";
import {
	getAssociatedUsers,
	clearAssociatedUsers,
} from "../../features/UserSlice";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

DataTable.Buttons.pdfMake(pdfMake);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Users() {
	const associatedUsers = useSelector((state) => state.user.associatedUsers);
	const [usersUsers, setUsersUsers] = useState([]);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [keyIncrementer, setKeyIncrementer] = useState(0);
	const [show, setShow] = useState(false);
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("");

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const sendSignupEmail = async (e) => {
		e.preventDefault();

		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("email", email);
		formData.append("role", role);

		const response = await axios
			.post(process.env.REACT_APP_ENV_URL + "/api/role/email", formData, {
				headers: {
					Authorization: "Bearer " + user.token,
				},
			})
			.catch((error) => {
				toast.error("Unable to send email", {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			});

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			handleClose();
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};

	useEffect(() => {
		dispatch(getAssociatedUsers());
		setRole(user.data.role == "SUPER ADMIN" ? "SUPER ADMIN" : "ADMIN");
		return () => {
			dispatch(clearAssociatedUsers());
		};
	}, []);

	useEffect(() => {
		$("#datatable").DataTable({
			pageLength: 10,
			lengthChange: true,
			lengthMenu: [10, 20, 50, 100, 200, 500],
			dom: "Blfrtip",
			buttons: [
				{ extend: "copyHtml5", filename: "users", title: "Users" },
				{ extend: "excelHtml5", filename: "users", title: "Users" },
				{ extend: "csvHtml5", filename: "users", title: "Users" },
				{
					extend: "pdfHtml5",
					filename: "users",
					title: "Users",
					orientation: "landscape",
				},
			],
		});
	}, [keyIncrementer]);

	useEffect(() => {
		/**
		 * Using a key and an incrementing it when this state
		 * changes will cause the element to be removed and
		 * re-rendered, this way we can refresh the datatable correctly
		 */
		setUsersUsers(associatedUsers);
		setKeyIncrementer(keyIncrementer + 1);
	}, [associatedUsers]);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Users
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Users</a>
											</li>
											<li className="breadcrumb-item active">
												Listing
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a listing of all users
												associated with this account.
												<span
													style={{ float: "right" }}
												>
													<a
														className="btn btn-success btn-md"
														style={{
															color: "white",
														}}
														onClick={() =>
															handleShow()
														}
													>
														<i className="fa fa-plus"></i>{" "}
														Sign up
													</a>
												</span>
											</p>
											<div key={keyIncrementer}>
												<table
													id="datatable"
													className="table table-bordered dt-responsive nowrap"
													style={{
														borderCollapse:
															"collapse",
														borderSpacing: 0,
														width: "100%",
													}}
												>
													<thead>
														<tr>
															<th>Name</th>
															<th>Email</th>
															<th>Role</th>
														</tr>
													</thead>

													<tbody>
														{usersUsers.map(
															(user, index) => {
																return (
																	<tr
																		key={
																			index
																		}
																	>
																		<td>
																			{
																				user.name
																			}
																		</td>
																		<td>
																			{
																				user.email
																			}
																		</td>
																		<td>
																			{
																				user.role
																			}
																		</td>
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>

				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Send sign up link</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: "3rem" }}>
						<div className="form-group row">
							<div className="col-sm-12">
								<label>Role</label>
								<select
									className="form-control"
									name="roles"
									id="roles"
									onChange={(e) => setRole(e.target.value)}
								>
									{user.data.role == "SUPER ADMIN" ? (
										<>
											<option value="SUPER ADMIN">
												CTC Admin
											</option>
											{/* <option value="ADMIN">Admin</option>
                      <option value="REPORTING">Reporting</option>
                      <option value="CAMPAIGN MANAGER">Campaign Manager</option> */}
										</>
									) : (
										<>
											<option value="ADMIN">Admin</option>
											<option value="REPORTING">
												Reporting
											</option>
											<option value="CAMPAIGN MANAGER">
												Campaign Manager
											</option>
										</>
									)}
								</select>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-sm-12">
								<label>Email</label>
								<input
									className="form-control"
									type="email"
									id="user-email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancel
						</Button>
						<Button variant="success" onClick={sendSignupEmail}>
							Send
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
}
