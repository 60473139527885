/* global $ (Imported in App.js) */

import Icon from '@mdi/react';
import { mdiCubeOutline, mdiLightningBolt, mdiEvStation, mdiNaturePeople, mdiCurrencyUsd, mdiAlertCircleOutline } from '@mdi/js';
import React, { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getNotifications } from '../../features/NotificationsSlice';
import LogoutButton from '../buttons/LogoutButton'
import useScriptImporter from '../hooks/useScriptImporter';
import { Link, useNavigate } from 'react-router-dom';
import { setToken } from '../../features/UserSlice';
import axios from 'axios';

export default function NavbarRight(props) {
  const scriptsImported = useScriptImporter()
  const navigate = useNavigate()
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)
  const user = useSelector(state => state.user)

  useEffect(() => {

    /**
     * Verify jQuery was imported (from App.js)
     */
    if (typeof window.$ === 'undefined') {
      forceUpdate();
      return
    }

    if (window.slimScroll === undefined) {
      const slimScrollScript = document.createElement("script");
      slimScrollScript.src = "assets/js/jquery.slimscroll.js";
      document.body.appendChild(slimScrollScript);

      forceUpdate();
      return;
    }

    $('.slimscroll-menu').slimscroll({
      height: 'auto',
      position: 'right',
      size: "7px",
      color: '#9ea5ab',
      wheelStep: 5,
      touchScrollStep: 50
    })

    $('.slimscroll').slimscroll({
      height: 'auto',
      position: 'right',
      size: "7px",
      color: '#9ea5ab',
      touchScrollStep: 50
    });
  })

  useEffect(() => {
    dispatch(getNotifications())

    let interval = setInterval(() => {
      dispatch(getNotifications()).then((result) => {

        // This used as an interval check to see if token has expired
        if (result.error !== undefined) {
          if (result.error.message.includes("401")) {
            localStorage.removeItem('ctc_token');
            dispatch(setToken(null))
            navigate('/login')
          }
        }

      })
    }, 10000)

    // On unmount
    return () => {
      clearInterval(interval);
    }
  }, [])

  const clearNotifications = async (user) => {
    const formData = new FormData()

    formData.append('token', user.token)
    formData.append('id', user.data.id)
    console.log(formData)
    const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/user/notifications/clear', formData, {
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).catch((error) => {
      console.log(error)
    })
    dispatch(getNotifications())

  }

  return (
    <ul className="navbar-right list-inline float-right mb-0">
      <li className="dropdown notification-list list-inline-item">
        <a className="nav-link dropdown-toggle arrow-none waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
          <i className="mdi mdi-bell-outline noti-icon"></i>
          <span className="badge badge-pill badge-danger noti-icon-badge">{Object.values(notifications.data).length}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-lg px-1">
          <div className='d-flex justify-content-between align-items-center'>
            <h6 className="dropdown-item-text">
              Notifications
            </h6>
            <div onClick={() => notifications.data.length != 0 && clearNotifications(user)} className='text-end text-sm-end mr-2 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover' style={{ cursor: "pointer" }}>Clear</div>
          </div>
          <div className="slimscroll notification-item-list">
            {
              Object.values(notifications.data).length > 0 ? Object.values(notifications.data).map((notification, index) => {
                let color_class;
                let mdi_icon;
                let title;

                if (notification.type.includes('Charger')) {
                  title = 'Charger'
                  mdi_icon = <Icon path={mdiLightningBolt} className="text-white" />
                } else if (notification.type.includes('Station')) {
                  title = 'Station'
                  mdi_icon = <Icon path={mdiNaturePeople} className="text-white" />
                } else if (notification.type.includes('Device')) {
                  title = 'Device'
                  mdi_icon = <Icon path={mdiCurrencyUsd} className="text-white" />
                } else {
                  title = 'General'
                  mdi_icon = <Icon path={mdiAlertCircleOutline} className="text-white" />
                }

                switch (notification.data.level) {
                  case 'info':
                    title = title + ' Info'
                    color_class = 'bg-info';
                    break;
                  case 'warning':
                    title = title + ' Warning'
                    color_class = 'bg-warning';
                    break;
                  case 'critical':
                    title = title + ' Error'
                    color_class = 'bg-danger';
                    break;
                  default:
                    title = title + ' Message'
                    color_class = 'bg-info'
                }

                return (
                  <div key={index} className="dropdown-item notify-item active">
                    <div className={`notify-icon ${color_class}`}>{mdi_icon}</div>
                    <p className="notify-details"><b>{title}</b><span className="text-muted">{notification.data.message}</span></p>
                  </div>
                )
              }) : <span className="dropdown-item-text">You have no new notifications!</span>
            }
          </div>
          <Link to="/notifications" className="dropdown-item text-center notify-all text-primary">
            View all <i className="fi-arrow-right"></i>
          </Link>
        </div>
      </li>

      <li className="dropdown notification-list list-inline-item">
        <div className="dropdown notification-list nav-pro-img">
          <a className="dropdown-toggle nav-link arrow-none nav-user" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <i className="mdi mdi-24px mdi-settings"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
            <a className="dropdown-item " href="#"><img src="assets/images/global.png" /></a>
            <div className="dropdown-divider"></div>
            <Link to="/profile" className="dropdown-item" href="#"><i className="mdi mdi-account-circle"></i> Profile</Link>
            <Link to="/settings" className="dropdown-item d-block" href="#"><i className="mdi mdi-settings"></i> Settings</Link>
            <div className="dropdown-divider"></div>
            <LogoutButton />
          </div>
        </div>
      </li>
    </ul>
  )
}
