/* eslint-disable */
import React, { useState, useEffect } from "react";
import Menu from "../sections/Menu";
import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-bs4";
import useScriptImporter from "../hooks/useScriptImporter";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../sections/TopBar";
import {
	getCampaigns,
	getInactiveCampaigns,
} from "../../features/CampaignsSlice";
import axios from "axios";
import { toast } from "react-toastify";
const dayjs = require("dayjs");

DataTable.Buttons.pdfMake(pdfMake);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function CancelledCampaigns() {
	const scriptsImported = useScriptImporter();
	const inactiveCampaigns = useSelector(
		(state) => state.campaigns.inactiveCampaigns
	);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [keyIncrementer, setKeyIncrementer] = useState(0);

	const activateCampaign = async (id) => {
		const formData = new FormData();

		formData.append("token", user.token);
		formData.append("campaign_id", id);

		const response = await axios
			.post(
				process.env.REACT_APP_ENV_URL + "/api/campaign/activate",
				formData,
				{
					headers: {
						Authorization: "Bearer " + user.token,
					},
				}
			)
			.catch((error) => {
				console.log(error);
			});

		if (response.data.code === 0) {
			toast.success(response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			dispatch(getCampaigns());
			dispatch(getInactiveCampaigns());
		} else {
			for (const [key, value] of Object.entries(response.data.errors)) {
				toast.error(`${value}`, {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}
	};
	useEffect(() => {
		dispatch(getInactiveCampaigns());
	}, []);

	useEffect(() => {
		$("#datatable").DataTable({
			pageLength: 10,
			lengthChange: true,
			lengthMenu: [10, 20, 50, 100, 200, 500],
			dom: "Blfrtip",
			buttons: ["copyHtml5", "excelHtml5", "csvHtml5", "pdfHtml5"],
		});
	}, [keyIncrementer]);

	useEffect(() => {
		/**
		 * Using a key and an incrementing it when this state
		 * changes will cause the element to be removed and
		 * re-rendered, this way we can refresh the datatable correctly
		 */
		setKeyIncrementer(keyIncrementer + 1);
	}, [inactiveCampaigns]);

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<div className="row align-items-center">
									<div className="col-sm-6">
										<h4 className="page-title">
											Your Cancelled Campaigns
										</h4>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb float-right">
											<li className="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li className="breadcrumb-item">
												<a>Campaigns</a>
											</li>
											<li className="breadcrumb-item active">
												Cancelled
											</li>
										</ol>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="card m-b-30">
										<div className="card-body">
											<p className="sub-title">
												This is a listing of all
												cancelled campaigns per account
												<span
													style={{ float: "right" }}
												></span>
											</p>
											<div key={keyIncrementer}>
												<table
													id="datatable"
													className="table table-bordered dt-responsive nowrap"
													style={{
														borderCollapse:
															"collapse",
														borderSpacing: 0,
														width: "100%",
													}}
												>
													<thead>
														<tr>
															<th>Name</th>
															<th>Type</th>
															<th>
																Recurring Period
															</th>
															<th>Start Date</th>
															<th>End Date</th>
															<th>Activate</th>
														</tr>
													</thead>

													<tbody>
														{inactiveCampaigns.length >
														0 ? (
															inactiveCampaigns.map(
																(
																	campaign,
																	index
																) => {
																	return (
																		<tr
																			key={
																				index
																			}
																		>
																			<td>
																				{
																					campaign.name
																				}
																			</td>
																			<td>
																				{
																					campaign.type
																				}
																			</td>
																			<td>
																				{campaign.recurring_period.replace(
																					"_",
																					" "
																				)}
																			</td>
																			<td>
																				{campaign
																					.campaign_times
																					.length >
																				0
																					? dayjs(
																							campaign
																								.campaign_times[0]
																								.start_time
																					  ).format(
																							"MM/DD/YYYY"
																					  )
																					: ""}
																			</td>
																			<td>
																				{campaign
																					.campaign_times
																					.length >
																				0
																					? dayjs(
																							campaign
																								.campaign_times[
																								campaign
																									.campaign_times
																									.length -
																									1
																							]
																								.end_time
																					  ).format(
																							"MM/DD/YYYY"
																					  )
																					: ""}
																			</td>
																			<td>
																				<button
																					className="btn btn-success"
																					onClick={() =>
																						activateCampaign(
																							campaign.id
																						)
																					}
																				>
																					Activate
																				</button>
																			</td>
																		</tr>
																	);
																}
															)
														) : (
															<></>
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
