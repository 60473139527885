import React from 'react'

export default function StatusTD(props) {

    if (props.status == 1) {
        return (
            <td style={{ textAlign: "center" }}>
                <span className="badge badge-success"><i className="fa fa-check"></i></span>
            </td>
        )
    } else {
        return (
            <td style={{ textAlign: "center" }}>
                <span className="badge badge-danger"><i className="fa fa-exclamation-triangle"></i></span>
            </td>
        )
    }
}