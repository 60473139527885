import React, { useState } from "react";
import NavbarRight from "../sections/NavbarRight";
import MobileMenuButton from "../buttons/MobileMenuButton";
import Menu from "../sections/Menu";
import imageCS from "../../assets/images/img-cs.png";
import TopBar from "../sections/TopBar";

export default function Support() {
	const [imageReady, setImageReady] = useState(false);

	const img = new Image();
	img.onload = () => {
		setImageReady(true);
	};
	img.src = imageCS;

	return (
		<>
			<div id="wrapper">
				<TopBar />
				<Menu />

				<div className="content-page">
					<div class="content">
						<div class="container-fluid">
							<div class="page-title-box">
								<div class="row align-items-center">
									<div class="col-sm-6">
										<h4 class="page-title">Help Desk</h4>
									</div>
									<div class="col-sm-6">
										<ol class="breadcrumb float-right">
											<li class="breadcrumb-item">
												<a href="/dashboard">Home</a>
											</li>
											<li class="breadcrumb-item">
												<a href="javascript:void(0);">
													Support
												</a>
											</li>
											<li class="breadcrumb-item active">
												Help Desk
											</li>
										</ol>
									</div>
								</div>
								<div>
									{(() => {
										if (imageReady) {
											return (
												<div class="container">
													<div class="row justify-content-center">
														<div class="col-lg-7">
															<div class="text-center mb-10">
																<div class="maintenance-img">
																	<img
																		src={
																			imageCS
																		}
																		alt=""
																		class="img-fluid mx-auto d-block"
																	/>
																</div>
																<h4>
																	Customer
																	Service
																	Center
																</h4>
																<p>
																	Have a
																	question or
																	need some
																	help in
																	getting your
																	Charge To
																	Charge
																	account
																	properly
																	configured?
																	No problem,
																	please
																	choose from
																	below to
																	request
																	support.
																</p>
															</div>
														</div>
													</div>

													<div class="row mt-4 align-items-center">
														<div class="col-lg-4">
															<div class="card mb-4">
																<div class="card-body">
																	<i class="mdi mdi-help-box font-30"></i>
																	<h5 class="font-18 mb-3">
																		Knowledge
																		Base
																	</h5>
																	<p class="text-muted mb-0">
																		Search
																		our
																		knowledge
																		Base for
																		commonly
																		asked
																		questions.
																		<br />
																		<br />
																		<center>
																			<a
																				href="#"
																				class="btn btn-success btn-md"
																			>
																				<i class="mdi mdi-link"></i>{" "}
																				Visit
																			</a>
																		</center>
																	</p>
																</div>
															</div>
														</div>
														<div class="col-lg-4">
															<div class="card mb-4">
																<div class="card-body">
																	<i class="mdi mdi-phone-classic font-30"></i>
																	<h5 class="mb-3 font-18">
																		Call Us
																	</h5>
																	<p class="text-muted mb-0">
																		Our
																		customer
																		support
																		team is
																		available
																		M-F
																		Between
																		8AM and
																		5PM MST.
																		<br />
																		<br />
																		<center>
																			<a
																				href="#"
																				class="btn btn-success btn-md"
																			>
																				<i class="mdi mdi-phone"></i>{" "}
																				Call
																				us
																			</a>
																		</center>
																	</p>
																</div>
															</div>
														</div>

														<div class="col-lg-4">
															<div class="card mb-4">
																<div class="card-body">
																	<i class="mdi mdi-email font-30"></i>
																	<h5 class="font-18 mb-3">
																		Email us
																	</h5>
																	<p class="text-muted mb-0">
																		Please
																		send us
																		an email
																		by using
																		the
																		button
																		below.
																		<br />
																		<br />
																		<center>
																			<a
																				href="#"
																				class="btn btn-success btn-md"
																			>
																				<i class="mdi mdi-send"></i>{" "}
																				Send
																				Email
																			</a>
																		</center>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											);
										} else {
											return <></>;
										}
									})()}
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					© 2023 Powered by Charge To Charge{" "}
					<span className="d-none d-sm-inline-block"></span>
				</footer>
			</div>
		</>
	);
}
