import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'

export const getNotifications = createAsyncThunk('user/getNotifications', async () => {
  const token = localStorage.getItem('ctc_token')

  const formData = new FormData()
  formData.append('token', token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/user/notifications', formData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })

  return response.data
})

export const getUnreadNotifications = createAsyncThunk('user/getUnreadNotifications', async () => {
  const token = localStorage.getItem('ctc_token')

  const formData = new FormData()
  formData.append('token', token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/user/notifications/unread', formData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })

  return response.data
})

export const getAllNotifications = createAsyncThunk('user/allGetNotifications', async () => {
  const token = localStorage.getItem('ctc_token')

  const formData = new FormData()
  formData.append('token', token)

  const response = await axios.post(process.env.REACT_APP_ENV_URL + '/api/user/notifications/all', formData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })

  return response.data
})

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    data: [], // 100 notifications
    fullList: [], // All notifications
    loading: '',
  },
  reducers: {
    setNotifications: (state, action) => {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = ''
    })
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = ''
    })
    builder.addCase(getAllNotifications.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      state.fullList = action.payload
      state.loading = ''
    })
    builder.addCase(getAllNotifications.rejected, (state, action) => {
      state.loading = ''
    })
  },
})

export const { setNotifications } = notificationsSlice.actions

export default notificationsSlice.reducer